export
    const allToleName = [
        { nepali: "पशुपति टोल", english: "Pashupati Tole", value: "pashupati_tole" },
        { nepali: "सरस्वति टोल", english: "Saraswoti Tole", value: "saraswoti_tole" },
        { nepali: "जोशी टोल", english: "Joshi Tole", value: "joshi_tole" },
        { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
        { nepali: "कृष्ण टोल", english: "Krishna tole", value: "krishna_tole" },
        {
            nepali: "मनकामना टोल, जलि",
            english: "Manakmana Tole",
            value: "manakmana_tole",
        },
        { nepali: "मनकामना टोल", english: "manamaka-tole", value: "manamaka-tole" },
        { nepali: "भवानी टोल", english: "Babani Tole", value: "bhabani-_tole" },
        {
            nepali: "शान्तिनगर टोल",
            english: "Santingar Tole",
            value: "santingar-_tole",
        },
        { nepali: "कैलाली पुल", english: "kailali tole", value: "kailali-tole" },
        { nepali: "त्रिवेणी चोक", english: "Tribani chowk", value: "tribani-chowk" },
        { nepali: "प्रगति टोल", english: "Pragati Tole", value: "pragati-tole" },
        { nepali: "नवदुर्गा टोल", english: "Nawadurga tole", value: "nawadurga" },
        { nepali: "लक्ष्मी नगर", english: "laxmi nagar", value: "laxmi_nagar" },
        {
            nepali: "पर्शिराम टोल",
            english: "Parshiram tole",
            value: "parshiram_tole",
        },
        { nepali: "ओल्ड एरपोर्ट", english: "Old Airport", value: "old_airport" },
        { nepali: "नैनादेवी टोल", english: "Nainadewi tole", value: "nainadewi_tol" },
        {
            nepali: "सृजनसिल टोल",
            english: "Srijansil tole",
            value: "srijan_sil_tole",
        },
        { nepali: "विनायक टोल", english: "Winayak tol", value: "winayak_tol" },
        { nepali: "अल एन चोक", english: "Alen chok", value: "alen_chok" },
        { nepali: "स्वर टोल", english: "Swar tole", value: "swar_tole" },
        { nepali: "शिव नगर", english: "Shivanagar", value: "shiv_nagar" },
        { nepali: "फाय मार्ग", english: "Phaya marg", value: "phaya_marg" },
        {
            nepali: "जयलक्ष्मी टोल",
            english: "Jayalaxmi tole",
            value: "jayalaxmi_tole",
        },
        {
            nepali: "मनिकपुर सिबिर",
            english: "Manikpur siwir",
            value: "manikpur_siwir",
        },
        { nepali: "मालपोत रोड", english: "Maalpot road", value: "maalpot_road" },
        { nepali: "पलाई वुड टोल", english: "Plywood tole", value: "ply_wood_tole" },
        { nepali: "सेतो पुल", english: "Setopul", value: "seto_pul" },
        { nepali: "पार्क मोड", english: "Paarkmod", value: "paark_mod" },
        { nepali: "हस्नपुर", english: "Hanspur", value: "hanspur" },
        { nepali: "बनदेवी", english: "Wandewi", value: "wandewi" },
        { nepali: "टोल", english: "Tole", value: "tole" },
        { nepali: "अदालत रोड", english: "Adalat road", value: "adalat_road" },
        { nepali: "बसन्त टोल", english: "Basant tole", value: "basant_tole" },
        { nepali: "NTC रोड", english: "ntc road", value: "ntc_road" },
        {
            nepali: "राधा कृष्ण टोल",
            english: "Radhakrishna tole",
            value: "radha_krishna_tole",
        },
        {
            nepali: "श्री गणेश टोल",
            english: "shreeganesh tole",
            value: "shree_ganesh_tole",
        },
        { nepali: "पृथ्वी टोल", english: "Prithwi tole", value: "prithwi_tole" },
        { nepali: "मंगलसेन टोल", english: "Mangalsen tole", value: "mangalsen_tole" },
        {
            nepali: "बसन्त टोल सिबिर",
            english: "Basant tole sibir",
            value: "basant_tole_1",
        },
        {
            nepali: "हरियाली टोल सिबिर",
            english: "Hariyali tole sibir",
            value: "hariyali_tole",
        },
        { nepali: "हरियाली टोल", english: "hariyali tole", value: "hariyali_tole_1" },
        { nepali: "आनन्द टोल", english: "Aanand tole", value: "aanand_tole" },
        {
            nepali: "शैलेश्वरी टोल",
            english: "Shaileswari tole",
            value: "shaileswari_tole",
        },
        { nepali: "मजिस्द रोड", english: "Masjid road", value: "masjid_road" },
        {
            nepali: "मिश्रा प्रिन्टर्स रोड",
            english: "Misra prints road",
            value: "misra_prints_road",
        },
        { nepali: "होस्पितल लाइन", english: "Hospital line", value: "hospital_line" },
        { nepali: "हनुमान टोल", english: "Hanumaantole", value: "hanumaan_tole" },
        { nepali: "पिपल चोक", english: "Pipal chok", value: "pipal_chok" },
        { nepali: "डिल्ली बजार", english: "Dilli bajar", value: "dilli_bajar" },
        { nepali: "मेन रोड", english: "Main road", value: "main_road" },
        { nepali: "बुद्ध टोल", english: "Buddha tole", value: "buddha_tole" },
        { nepali: "बुद्ध चोक", english: "Buddha chok", value: "buddha_chok" },
        { nepali: "साइराम टोल", english: "Sairam tole", value: "sai_ram_tole" },
        { nepali: "चौराहा", english: "chauraha", value: "chauraaha" },
        { nepali: "मेनरोड", english: "Mainroad", value: "mainroad" },
        { nepali: "बैया बेहेदी", english: "baiya Behedi", value: "baiya_behedi" },
        { nepali: "रतो पुल", english: "Rato pul", value: "rato_pul" },
        { nepali: "कैलाली पुल", english: "kailaali pul", value: "kailaali_pul" },
        { nepali: "सन्तोषी टोल", english: "santoshi tole", value: "santoshi_tole" },
        {
            nepali: "सरस्वति नगर",
            english: "Saraswati nagar",
            value: "saraswati_nagar",
        },
        { nepali: "नया बस्ति", english: "Naya basti", value: "naya_basti" },
        { nepali: "कृष्ण नगर", english: "Krishna nagar", value: "krishna_nagar" },
        {
            nepali: "सिता पथ, चौरहा",
            english: "Sitapath,chauraha",
            value: "sita_path,_chauraha",
        },
        { nepali: "मलिका टोल", english: "Malika tole", value: "malika_tole" },
        { nepali: "शिखर टोल", english: "Shikhar tole", value: "shikhar_tole" },
        { nepali: "मिलन टोल", english: "Milan tole", value: "milan_tole" },
        { nepali: "लिल्के टोल", english: "Lilke tole", value: "lilke_tole" },
        { nepali: "आदर्श टोल", english: "Aadarsh tole", value: "aadarsh_tole" },
        {
            nepali: "पुरानो भन्सार रोड",
            english: "Puranobhansar road",
            value: "purano_bhansar_road",
        },
        { nepali: "कालिका टोल", english: "Kalika tole", value: "kalika_tole" },
        {
            nepali: "राम जानकी टोल",
            english: "Ramjanaki tole",
            value: "ram_janaki_tole",
        },
        { nepali: "राम नगर", english: "Ram nagar", value: "ram_nagar" },
        { nepali: "नवादिप टोल", english: "Nawadip tole", value: "nawadip_tole" },
        {
            nepali: "सिद्धार्थ टोल",
            english: "Siddharth tole",
            value: "siddharth_tole",
        },
        {
            nepali: "सिंगापुरे टोल",
            english: "Singapuree tole",
            value: "singapuree_tole",
        },
        {
            nepali: "कृष्ण नगर, चौराहा",
            english: "krishna nagar, chauraha",
            value: "krishna_nagar_1",
        },
        { nepali: "कृष्ण नगर", english: "krishna nagar", value: "krishna_nagar_2" },
        { nepali: "रैका टोल", english: "Raika tole", value: "raika_tole" },
        { nepali: "बैदनाथ टोल", english: "Baidnaath tole", value: "baidnaath_tole" },
        { nepali: "एभरेस्ट टोल", english: "Everest tole", value: "everest_tole" },
        { nepali: "श्रीलंका", english: "Srilanka", value: "srilanka" },
        { nepali: "लागृति टोल", english: "Lagriti tole", value: "lagriti_tole" },
        { nepali: "भवानी टोल", english: "Bhawani tole", value: "bhawani_tole" },
        { nepali: "भन्सार", english: "Bhansaar", value: "bhansaar" },
        { nepali: "भन्सार रोड", english: " Bhansaar road", value: "bhansaar_road" },
        { nepali: "मिलन चोक", english: "Milan chowk", value: "milan_chowk" },
        {
            nepali: "भन्सार-राजपुर",
            english: "Bhansaar raajpur",
            value: "bhansaar_raajpur",
        },
        { nepali: "मिलन मार्ग", english: "Milan marg", value: "milan_marg" },
        { nepali: "चटकपुर", english: "Chatakpur", value: "chatakpur" },
        { nepali: "भन्सार", english: "bhansar", value: "bhansar" },
        { nepali: "मिलन टोल", english: "Milantole", value: "milantole" },
        { nepali: "केराबारी", english: "Kera bari", value: "kera_bari" },
        { nepali: "बडारा गाउँ", english: "Bedara Gaun", value: "bdara_gaun" },
        { nepali: "मिलन चोक", english: "Milan chowk", value: "milan_chowk" },
        { nepali: "डीपन्द्र टोल", english: "Dipendra tole", value: "dipendra_tole" },
        { nepali: "बद्र गाउँ", english: "Badra gaun", value: "badra_gaun" },
        { nepali: "बधारा", english: "Badhara", value: "badhara" },
        { nepali: "विशालनगर", english: "Bishalnagar", value: "bishalnagar" },
        { nepali: "भैरव टोल", english: "Bhairaw tole", value: "bhairaw_tole" },
        { nepali: "बोराडन्डी", english: "Worodandi", value: "worodandi" },
        { nepali: "गुरुद्वार", english: "Gurudwar", value: "gurudwar" },
        { nepali: "बेहांदी", english: "Bihandi", value: "bihandi" },
        { nepali: "कचण टोल", english: "Kachan tole", value: "kachan_tole" },
        { nepali: "जनता मार्ग", english: "Janata marg", value: "janata_marg" },
        { nepali: "डिलासैनी", english: "Dilasaini", value: "dilasaini" },
        { nepali: "सिद्धिविनायक", english: "Siddibinayak", value: "siddibinayak" },
        { nepali: "बोरादंदी", english: "Boradandi", value: "boradandi" },
        { nepali: "गुरुद्वार", english: "Gurudwar", value: "gurudwar_1" },
        {
            nepali: "त्रिभुवन मार्ग",
            english: "Tribhuwan marg",
            value: "tribhuwan_marg",
        },
        { nepali: "केदार", english: "Kedar", value: "kedar" },
        { nepali: "बोराडाडी", english: "Boradadi", value: "boradadi" },
        { nepali: "सोडन्डी", english: "Sodandi", value: "sodandi" },
        {
            nepali: "भीमदत्त हाइवे साइड",
            english: "Bhimdatt highway side",
            value: "bhimdatt_haighway_said",
        },
        { nepali: "भगवती टोल", english: "Bhagwati tole", value: "bhagwati_tole" },
        { nepali: "चौराह", english: "Chauraha", value: "chauraha" },
        { nepali: "राजपुर", english: "Rajpur", value: "rajpur" },
        { nepali: "चौराहा", english: "Chauraha", value: "chauraha" },
        { nepali: "बस पार्क", english: "Buspark", value: "buspark" },
        { nepali: "कैलाली पुल", english: "Kailai pul", value: "kailai_pul" },
        { nepali: "कैलाली गाउँ", english: "Kailali gaun", value: "kailali_gaun" },
        { nepali: "बोराडन्डी", english: "Boradandi", value: "boradandi" },
        { nepali: "उत्तर बेहंदी", english: "Uttar behandi", value: "uttar_behandi" },
        { nepali: "तारा नगर", english: "Taara nagar", value: "taara_nagar" },
        {
            nepali: "ॐ शान्ति टोल ",
            english: "Om shanti tole",
            value: "om_shanti_tole",
        },
        {
            nepali: "खानेपानी टोल",
            english: "khanepani tole",
            value: "khanepani_tole",
        },
        { nepali: "बेह्दी", english: "Behandi", value: "behandi" },
        { nepali: "चटकपुर", english: "Chatakpur", value: "chatakpur" },
        { nepali: "बेहंदी", english: "Behaandi", value: "behandi_1" },
        { nepali: "शिवनगर", english: "Shibnagar", value: "shibnagar" },
        { nepali: "जय टोल", english: "Jayatole", value: "jayatole" },
        { nepali: "भैरब टोल", english: "Bhairab tole", value: "bhairab_tole" },
        {
            nepali: "उत्तरे बेहंदी",
            english: "Uttare behandi",
            value: "uttare_behandi",
        },
        { nepali: "तारा नगर", english: "Tara nagar", value: "tara_nagar" },
        { nepali: "हस्नपुर", english: "Hanspur", value: "hanspur" },
        { nepali: "ज्योति नगर", english: "Jyoti nagar", value: "jyoti_nagar" },
        { nepali: "भैरब टोल", english: "Bhairab tole 1", value: "bhairab_tole_1" },
        { nepali: "नमुना टोल", english: "Namuna tole", value: "namuna_tole" },
        {
            nepali: "नमुना टोल, तारानगर",
            english: "Namuna tole taranagar",
            value: "namuna_tole_taranagar",
        },
        {
            nepali: "जय आभास नगर टोल",
            english: "Jayaaabhas nagar tole",
            value: "jaya_aabhas_nagar_tole",
        },
        { nepali: "जोइ टोल", english: "Joi tole", value: "joi_tole" },
        {
            nepali: "गौरश्सोर टोल",
            english: "Gaureshsor tole",
            value: "gaureshsor_tole",
        },
        { nepali: "दाहाल टोल", english: "Dahal tole", value: "dahal_tole" },
        { nepali: "गणेस टोल", english: "Ganesh tole", value: "ganesh_tole" },
        {
            nepali: "मनकामना टोल",
            english: "Manakamana tole",
            value: "manakamana_tole",
        },
        { nepali: "कैलाश टोल", english: "Kailash tole", value: "kailash_tole" },
        { nepali: "प्रभात टोल", english: "Prabhat tole", value: "prabhat_tole" },
        { nepali: "प्रभाब टोल", english: "Prabhab tole", value: "prabhab_tole" },
        { nepali: "सद्भाव टोल", english: "Sadbhab tole", value: "sadbhab_tole" },
        {
            nepali: "सिद्धि गणेश टोल,जाली",
            english: "Siddhiganesh tole,Jaali",
            value: "siddhi_ganesh_tole,_jaali",
        },
        { nepali: "भैरब टोल", english: "Bhairab tole", value: "bhairab_tole" },
        { nepali: "बिजुलिया गाउँ", english: "Bijuliya gaun", value: "bijuliya_gaun" },
        {
            nepali: "शान्ति टोल , जाली",
            english: "Shanti tole, jaali",
            value: "shanti_tole,_jaali",
        },
        {
            nepali: "पिपल चोक, जाली",
            english: "Pipal chok, jaali",
            value: "pipal_chok,_jaali",
        },
        {
            nepali: "जव दुर्गा टोल, जली",
            english: "Jawa durga tole, jali",
            value: "jawa_durgaa_tole,_jali",
        },
        {
            nepali: "मनकामना टोल",
            english: "Manakamana tole",
            value: "manakamana_tole",
        },
        { nepali: "हरियाली टोल", english: "Hariyali tole", value: "hariyali_tole" },
        { nepali: "शिव टोल", english: "Shiv tole", value: "shiv_tole" },
        { nepali: "सालघारी", english: "Salkhari", value: "salkhari" },
        { nepali: "पपुलर", english: "Popular", value: "papular" },
        { nepali: "कृष्ण टोल", english: "Krishna tole", value: "krishna_tole" },
        { nepali: "कृष्ण नगर", english: "krishna nagar", value: "krishna_nagar" },
        { nepali: "शान्ति टोल", english: "Shanti tole", value: "shanti_tole" },
        {
            nepali: "शान्ति टोल, म्त्यारी",
            english: "Shanti tole, mnyaari",
            value: "shanti_tole,_mnyaari",
        },
        { nepali: "ढुंगे बजार", english: "Dhunge bajar", value: "dhunge_bajar" },
        { nepali: "डी गाउँ", english: "D gaun", value: "d_gaun" },
        {
            nepali: "श्री कृष्णटोल",
            english: "shree krishna tole",
            value: "shree_krishna_tole",
        },
        { nepali: "भैरव टोल", english: "Bhairab tole", value: "bhairab_tole_1" },
        { nepali: "जै टोल", english: "Jai tole", value: "jai_tole" },
        { nepali: "नमुना टोल", english: "Namuna tole", value: "namuna_tole" },
        { nepali: "नमुना टोल", english: "Namuna tole", value: "namuna_tole_1" },
        { nepali: "बिर चोक", english: "Bir chowk", value: "bir_chowk" },
        { nepali: "तारानगर", english: "Taranagar", value: "taranagar" },
        { nepali: "प्रभात टोल", english: "Prabhat tole", value: "prabhat_tole" },
        {
            nepali: "बिस्वनाथ टोल",
            english: "Biswanath tole",
            value: "biswanath_tole",
        },
        { nepali: "बिजुलिया", english: "Bijuliya", value: "bijuliya" },
        { nepali: "बसन्त टोल", english: "Basaant tole", value: "basaant_tole" },
        { nepali: "सिद्धि गणेश", english: "Siddhi ganesh", value: "siddhi_ganesh" },
        {
            nepali: "सिद्धार्थ टोल",
            english: "Siddharth tole",
            value: "siddharth_tole",
        },
        { nepali: "शिवनगर", english: "shiv nagar", value: "shiv_nagar" },
        {
            nepali: "दोघरा टोल, बिजुलिया",
            english: "Todhara tole,bijuliya",
            value: "todhara_tole,_bijuliya",
        },
        {
            nepali: "डोघर टोल , जाली",
            english: "Doghar_tole,jaal",
            value: "digharaa_tole,_jaal",
        },
        { nepali: "सुर्योदय", english: "Suryodaya", value: "suryodaya" },
        { nepali: "गुराह टोल", english: "Guraha tole", value: "guraha_tole" },
        {
            nepali: "श्री लक्ष्मी टोल",
            english: "Shree laxmi tole",
            value: "shree_laxmi_tole",
        },
        { nepali: "जागृति टोल", english: "Jaagriti tole", value: "jaagriti_tole" },
        {
            nepali: "उत्तरे बेहंदी",
            english: "Uttare behandi",
            value: "uttare_behandi",
        },
        { nepali: "भीमदत्त टोल", english: "Bhimdatt tole", value: "bhimdatt_tole" },
        {
            nepali: "नमुना टोल, मत्यारी",
            english: "Namuna tole, matyari",
            value: "namuna_tole,_matyari",
        },
        { nepali: "कि सिंह टोल", english: "Ki Sing Tole", value: "ki..sing._tole" },
        { nepali: "नाजारिया", english: "Najariya", value: "najariya" },
        { nepali: "पंच्कोती", english: "Panchakoti", value: "panchakoti" },
        { nepali: "बुद्ध टोल", english: "Buddhatole", value: "buddha_tole" },
        { nepali: "संगम टोल", english: "sangam tole", value: "sangam_tole" },
        { nepali: "मोहनियल टोल", english: "Mohaniyal tole", value: "mohaniyal_tole" },
        {
            nepali: "नाजारिया टोल, मत्यारी",
            english: "Najariya tole, manyari",
            value: "najariya_tole,_manyari",
        },
        {
            nepali: "प्रगतिशील टोल",
            english: "Pratishiltole",
            value: "pratishil_tole",
        },
        { nepali: "बेला", english: "Bela", value: "bela" },
        { nepali: "लेकाली गाउँ", english: "Lekali gaun", value: "lekali_gaun" },
        { nepali: "हिमाली टोल", english: "Himali tole", value: "himali_tole" },
        { nepali: "पतेला", english: "Patala", value: "patala" },
        { nepali: "बेली", english: "Beli", value: "beli" },
        { nepali: "बेला", english: "Bela", value: "bela" },
        { nepali: "डेवि मन्दिर", english: "Dewi Mandir", value: "dewi_mandir" },
        { nepali: "देवनागर टोल", english: "Dewnagar Tole", value: "dewnagar_tole" },
        { nepali: "पचली पट्टि", english: "Pchali patti", value: "pchali_patti" },
        {
            nepali: "अग्लिपत्ति टोल",
            english: "Aglipatti Tole",
            value: "aglipatti_tole",
        },
        {
            nepali: "सरस्वाती टोल",
            english: "Saraswati Tole",
            value: "saraswati_tole",
        },
        { nepali: "पश्चिम टोल", english: "Paschim Tole", value: "paschim_tole" },
        { nepali: "मन्हेरा", english: "Manhera", value: "manhera" },
        { nepali: "सिर्जना टोल", english: "Sirjana Tole", value: "sirjana_tole" },
        {
            nepali: "राम भगत टोल",
            english: "Ram bhagat tole",
            value: "ram_bhagat_tole",
        },
        { nepali: "गौरी टोल", english: "Gauri tole", value: "gauri_tole" },
        { nepali: "मन्हेरा सिबिर", english: "Manhera Sibir", value: "manhera_sibir" },
        {
            nepali: "सिर्पत्ति-बगिया",
            english: "Sirptti Bagiya",
            value: "sirptti_bagiya",
        },
        { nepali: "पश्चिम टोल", english: "Paschim Tole1", value: "paschim_tole_1" },
        { nepali: "सिमल टोल", english: "Simal Tole", value: "simal_tole" },
        { nepali: "गौरी टोल", english: "Gauri Tole 1", value: "gauri_tole_1" },
        {
            nepali: "गौरी टोल , मन्हेरा सिबिर",
            english: "GauriTole Mnhera Sibir",
            value: "gauri_tole,_mnhera_sibir",
        },
        { nepali: "टिलकी टोल", english: "Tilki Tole", value: "tilki_tole" },
        {
            nepali: "शान्तिनगर टोल",
            english: "Shanti Nagar Tole",
            value: "shanti_nagar_tole",
        },
        { nepali: "क्याम्पस रोद", english: "Campus Road", value: "campur_road" },
        { nepali: "गौरी टोल", english: "Gauri Tole 2", value: "gauri_tole_2" },
        { nepali: "जोखर टोल", english: "Jokhar Tole", value: "jokhar_tole" },
        { nepali: "डेबरिय", english: "Debariya", value: "debariya" },
        {
            nepali: "सरस्वति टोल",
            english: "Saraswati Tole 1",
            value: "saraswati_tole_1",
        },
        {
            nepali: "आशा नगर टोल",
            english: "Aasha Nagar Tole",
            value: "aasha_nagar_tole",
        },
        { nepali: "जखौर टोल", english: "Jokhaur Tole", value: "jokhaur_tole" },
        { nepali: "कालिका टोल", english: "Kaalika Tole", value: "kaalika_tole" },
        { nepali: "नमुना टोल", english: "Namuna Tole", value: "namuna_tole" },
        { nepali: "अनामनगर", english: "Anamnagar", value: "anamnagar" },
        { nepali: "मोति चोक", english: "Moti Chowk", value: "moti_chowk" },
        { nepali: "भानु चोक", english: "Bhanu Chowk", value: "bhanu_chowk" },
        { nepali: "हेकुली टोल", english: "Hekuli Tole", value: "hekuli_tole" },
        {
            nepali: "जनज्योति टोल",
            english: "Janajyoti Tole",
            value: "janajyoti_tole",
        },
        { nepali: "मानिया टोल", english: "Maaniya Tole", value: "maaniya_tole" },
        { nepali: "पृथ्वी टोल", english: "Prithwi Tole", value: "prithwi_tole" },
        {
            nepali: "दक्षिण काली टोल",
            english: "Dakshin Kali Tole",
            value: "dakshin_kali_tole",
        },
        {
            nepali: "दक्षिणपट्टि टोल",
            english: "Dakshin Patti Tole",
            value: "dakshin_patti_tole",
        },
        {
            nepali: "सद्ब बाबा टोल",
            english: "Sadda Baba Tole",
            value: "dad_baba_tole",
        },
        { nepali: "आदर्शटोल", english: "Darsh Tole", value: "darsh_tole" },
        {
            nepali: "सरुवा बाबा टोल",
            english: "Saruwaa Baba Tole",
            value: "saruwaa_baba_tole",
        },
        { nepali: "लम्बा टोल", english: "Lamba Tole", value: "lamba_tole" },
        {
            nepali: "पचार पट्टि टोल",
            english: "Pchar Patti Tole",
            value: "pchar_patti_tole",
        },
        { nepali: "बुद्ध चोक", english: "Buddha Chowk", value: "buddha_chowk" },
        { nepali: "सेतोपुल", english: "Setopul", value: "setopul" },
        { nepali: "क्यम्पुस रोद", english: "Campus Road", value: "campur_road" },
        { nepali: "एसोर्य टोल", english: "Aisosrya Tole", value: "aisosrya_tole" },
        { nepali: "मनेहारा", english: "Manehara", value: "mnehara" },
        { nepali: "सरस्वति टोल", english: "Saraswati Tole", value: "saraswati_tole" },
        {
            nepali: "सरस्वति टोल, मनेहारा",
            english: "Saraswati Tole,Manehara",
            value: "saraswati_tole,_manehara",
        },
        { nepali: "सम्झना टोल", english: "Samjhana Tole", value: "samjhana_tole" },
        { nepali: "कान्तिपुर टोल", english: "Kantipur Tole", value: "kantipur_tole" },
        { nepali: "मोति चोक", english: "Moti Chowk", value: "moti_chowk" },
        {
            nepali: "सद्भावना टोल",
            english: "Sadbhawana Tole",
            value: "sadbhawana_tole",
        },
        { nepali: "सत्कर मार्ग", english: "Satkar Marg", value: "satkar_marg" },
        {
            nepali: "शिक्ष्यानगर टोल",
            english: "Sikshya Nagr Tole",
            value: "sikshya_nagr_tole",
        },
        { nepali: "क्यम्पुस रोड", english: "Campus Road1", value: "campur_road_1" },
        { nepali: "सस्कार मार्ग", english: "Sanskar Marg", value: "sanskar_marg" },
        { nepali: "शिक्षा मार्ग", english: "Sikshya Marg", value: "sikshya_marg" },
        { nepali: "सभ्यता टोल", english: "Sabhyata Tole", value: "sabhyata_tole" },
        { nepali: "अयोद्या मार्ग", english: "Ayodhya Marg", value: "ayodhya_marg" },
        {
            nepali: "अपि सैपाल टोल",
            english: "Api Saipal Tole",
            value: "api_laipal_tole",
        },
        { nepali: "श्रीस्ती टोल", english: "Shreesti Tole", value: "shreesti_tole" },
        { nepali: "रामनगर", english: "Raamnagar", value: "raamnagar" },
        { nepali: "शिवनगर", english: "Shiv Nagar", value: "shiv_nagar" },
        { nepali: "बुद्ध टोल", english: "Buddhaa Tole", value: "buddhaa_tole" },
        { nepali: "विद्यानगर ", english: "Widhyanagar", value: "widhyanagar" },
        { nepali: "उत्टर बेहंदी", english: "Uttar Behandi", value: "uttar_behandi" },
        { nepali: "अनामनगर", english: "Anamnagar", value: "anamnagar" },
        { nepali: "प्रगति टोल", english: "Pragati Tole", value: "pragati_tole" },
        { nepali: "धनगढी गाउँ", english: "Dhangahi Gaun", value: "dhangahi_gaun" },
        {
            nepali: "राधा कृष्ण टोल",
            english: "Radha Krishna Tole",
            value: "radha_krishna_tole",
        },
        {
            nepali: "राम जानकी टोल",
            english: "Ram Janaki Tole",
            value: "ram_janaki_tole",
        },
        {
            nepali: "शुभकामना टोल",
            english: "Shuvakamana Tole",
            value: "shuvakamana_tole",
        },
        { nepali: "शिव टोल", english: "Shiv Tole", value: "shiv_tole" },
        { nepali: "सिमाना टोल", english: "Simana Tole", value: "simana_tole" },
        { nepali: "भाषा टोल", english: "Bhasa Tole", value: "bhasa_tole" },
        {
            nepali: "नवदुर्गा टोल",
            english: "Nawadurga Tole",
            value: "nawadurga_tole",
        },
        {
            nepali: "रह स्वामी टोल",
            english: "Raha SwamiTole",
            value: "raha_swami_tole",
        },
        { nepali: "मुस्कान टोल", english: "Muskan Tole", value: "muskan_tole" },
        { nepali: "भद्र टोल", english: "Bhadra Tole", value: "bhadra_tole" },
        { nepali: "मिलन टोल", english: "Milan Tole", value: "milan_tole" },
        { nepali: "भद्र टोल", english: "Bhadra Tole 1", value: "bhadra_tole_1" },
        { nepali: "मिलन टोल", english: "Milan Tole 1", value: "milan_tole_1" },
        { nepali: "संगम टोल", english: "Sangam Tole", value: "sangam_tole" },
        { nepali: "गणेश टोल", english: "Ganesh Tole", value: "ganesh_tole" },
        {
            nepali: "मन्हेरा सिबिर ",
            english: "Manhera Siwir",
            value: "manhera_siwir",
        },
        { nepali: "बुद्ध चोक", english: "Buddha Chowk", value: "buddha_chowk" },
        { nepali: "शिवनगर ", english: "Shiv Nagar", value: "shiv_nagar" },
        { nepali: "श्री रामनगर", english: "Shree Ramnagar", value: "shree_ramnagar" },
        { nepali: "ब्न्देवी टोल", english: "Bndewi Tole", value: "bndewi_tole" },
        { nepali: "बांग्र कटान", english: "Banga Katan", value: "banga_katan" },
        { nepali: "सरस्वति टोल", english: "Saraswati Tole", value: "saraswati_tole" },
        { nepali: "हिव टोल", english: "Hib Tole", value: "hib_tole" },
        { nepali: "सिमना टोल", english: "Simana Tole", value: "simana_tole" },
        { nepali: "धनगढी गाउँ", english: "Dhangadhi Gaun", value: "dhangadhi_gaun" },
        { nepali: "भगवती टोल", english: "Bhagwati Tole", value: "bhagwati_tole" },
        { nepali: "बांग्रा", english: "Bangna", value: "bangna" },
        { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
        { nepali: "हरियाली टोल", english: "Hariyali Tole", value: "hariyali_tole" },
        { nepali: "तारा ताल", english: "Tara Tal", value: "tara_tal" },
        { nepali: "गौरी टोल", english: "Gauri Tole", value: "gauti_tole" },
        { nepali: "टिकाराम चोक", english: "Tikaram Chowk", value: "tikaram_chowk" },
        {
            nepali: "गौरी ताराताल टोल",
            english: "Gauri Taratal Tolee",
            value: "gauri_taratal_tole",
        },
        {
            nepali: "सैलेश्वारी टोल",
            english: "Saileshwari Tole",
            value: "saileshwari_tole",
        },
        {
            nepali: "लाली गुराँस टोल",
            english: "Lalikguras Tole",
            value: "lalikguras_tole",
        },
        {
            nepali: "शिव पार्वती टोल",
            english: "Shiv Parwati Tole",
            value: "shiv_parwati_tole",
        },
        { nepali: "जुगेड कटान", english: "Juged Katan", value: "juged_katan" },
        { nepali: "बन्देवी टोल", english: "Bandewi Tole", value: "bandewi_tole" },
        { nepali: "भट्ट टोल", english: "Bhatta Tole", value: "bhatta_tole" },
        {
            nepali: "रम भगत टोल",
            english: "Ram Bhagat Tole",
            value: "ram_bhagat_tole",
        },
        { nepali: "पच्लिपत्ति ", english: "Pachlipatti", value: "pachlipatti" },
        { nepali: "उत्तर टोल", english: "Uttar Tole", value: "uttar_tole" },
        { nepali: "मिलन टोल", english: "Milan Tole", value: "milan_tole" },
        { nepali: "धनगढी गाउँ", english: "Dhangadhi Gaun", value: "dhangadhi_gaun" },
        { nepali: "कान्तिपुर टोल", english: "Kantipur Tole", value: "kantipur_tole" },
        { nepali: "घुइयाघाट", english: "Dhuiya Ghat", value: "dhuiya_ghat" },
        { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
        { nepali: "बि.पी  टोल", english: "B.P Tole", value: "b.p_tole" },
        {
            nepali: "जन कल्याण टोल",
            english: "Jana kalyan Tole",
            value: "jana_kalyan_tole",
        },
        { nepali: "लक्ष्मी टोल", english: "Laxmi Tole", value: "laxmi_tole" },
        { nepali: "पुल्न्हिया", english: "Punlihaya", value: "punlihaya" },
        { nepali: "भगत टोल", english: "Bhagat Tole", value: "bhagat_tole" },
        { nepali: "भगवती टोल", english: "Bhagwati Tole", value: "bhagwati_tole" },
        {
            nepali: "जनजागृति टोल",
            english: "Janajaagriti Tole",
            value: "janajaagriti_tole",
        },
        { nepali: "गणपति टोल", english: "Ganapati Tole", value: "ganapati_tole" },
        { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
        {
            nepali: "लालीगुराँस टोल",
            english: "Laliguras Tole",
            value: "laliguras_tole",
        },
        {
            nepali: "खानेपानी टोल",
            english: "Khanepani Tole",
            value: "khanepani_tole",
        },
        { nepali: "खापरमंडू", english: "khaparmandu", value: "khaparmandu" },
        { nepali: "बि गाउँ", english: "B gaun", value: "b_gaun" },
        { nepali: "जोकैया", english: "Jokaiyaa", value: "jokaiyaa" },
        { nepali: "बेला", english: "Belaa", value: "belaa" },
        { nepali: "बर्बब्त्ता", english: "Barbbtaa", value: "barbbtaa" },
        { nepali: "बारबारा", english: "Barbaara", value: "barbaara" },
        { nepali: "धोद्सुवा", english: "Dhodsuwa", value: "dhodsuwa" },
        { nepali: "बस्खेदा", english: "Baskheda", value: "baskheda" },
        { nepali: "बिजुलिया गाउँ", english: "Bijuliya Gaun", value: "bijuliya_gaun" },
        {
            nepali: "श्री कृष्ण टोल",
            english: "Shree Krishna Tole",
            value: "shree_krishna_tole",
        },
        { nepali: "मत्यारी", english: "Matyaari", value: "matyaari" },
        { nepali: "बुजली टोल", english: "Bujali Tole", value: "bujali_tole" },
        { nepali: "बुजुलाया टोल", english: "Bujulaya Tole", value: "bujulaya_tole" },
        { nepali: "मतियारी टोल", english: "Mitiyari Tole", value: "mitiyari_tole" },
        {
            nepali: "मालो मंदरी टोल",
            english: "Lamo Mandri Tole",
            value: "lamo_mandri_tole",
        },
        { nepali: "मलो मदारी", english: "Mela Madari", value: "mela_madari" },
        { nepali: "नमुना टोल", english: "Namuna Tole", value: "namuna_tole" },
        { nepali: "कि सिंह टोल", english: "Ki Singh Tole", value: "ki_singh_tole" },
        { nepali: "बुद्ध टोल", english: "Buddha Tole", value: "buddha_tole" },
        {
            nepali: "दिर्घ ताल टोल",
            english: "Dirgha Tal Tole",
            value: "dirgha_tal_tole",
        },
        { nepali: "तारा नगर", english: "Tara Nagar", value: "tara_nagar" },
        {
            nepali: "दक्षिण काली टोल",
            english: "Dakshin Kali Tole",
            value: "dakshin_kali_tole",
        },
        { nepali: "घुर्सुवा टोल", english: "Ghursuwa Tole", value: "ghursuwa_tole" },
        { nepali: "घोद्सुवा टोल", english: "Dhodsuwa Tole", value: "dhodsuwa_tole" },
        { nepali: "धम्लन टोल", english: "Dhamlan Tole", value: "dhamlan_tole" },
        { nepali: "घुर्सुवा", english: "Ghursuwa", value: "ghursuwa" },
        { nepali: "बैसेगेदा", english: "Baisegeda", value: "baisegeda" },
        { nepali: "आम्बिका टोल", english: "Ambika Tole", value: "ambika_tole" },
        { nepali: "बसिगद्द", english: "Basigadda", value: "basigadda" },
        { nepali: "चौधरी टोल", english: "Chaudhari Tole", value: "chaudhari_tole" },
        { nepali: "फुक्लापुर", english: "Phuklapur", value: "phuklapur" },
        { nepali: "घोत्सुवा टोल", english: "Ghotsuwa Tole", value: "ghotsuwa_tole" },
        { nepali: "बेलादेपुर", english: "Beladepur", value: "beladepur" },
        { nepali: "देव पुर", english: "Dev Pur", value: "dev_pur" },
        { nepali: "देवपुर य", english: "Deb Pur a", value: "deb_pur_a" },
        { nepali: "फुकलपुर", english: "Phuklpur", value: "phuklpur" },
        { nepali: "कैलाली गाउँ", english: "Kailali Gaun", value: "kailali_gaun" },
        { nepali: "लक्ष्मी नगर", english: "Laxmi Nagar", value: "laxmi_nagar" },
        { nepali: "भात्त टोल", english: "Bhatt Tole", value: "bhatt_tole" },
        { nepali: "डी गाउँ", english: "D Gaun", value: "d_gaun" },
        { nepali: "शि गाउँ", english: "C Gaun", value: "c_gaun" },
        {
            nepali: "सिर्जनसिल टोल",
            english: "Sirjansil Tole",
            value: "sirjansil_tole",
        },
        {
            nepali: "शिवशक्ति टोल",
            english: "Shivshakti Tole",
            value: "shivshakti_tole",
        },
        { nepali: "राइ टोल", english: "Rai Tole", value: "rai_tole" },
        { nepali: "बि गाउँ", english: "B Gaun", value: "b_gaun" },
        { nepali: "पश्चिम टोल", english: "Paschim Tole", value: "paschim_tole" },
        { nepali: "पश्चिम टोल", english: "Paschim Tole.", value: "paschim_tole." },
        { nepali: "सिमल टोल", english: "Simal Tole", value: "simal_tole" },
        { nepali: "सन्तोषी टोल", english: "Santoshi Tole", value: "santoshi_tole" },
        { nepali: "चंकी बजार", english: "Chanki Bajar", value: "chanki_bajar" },
        { nepali: "कम्पनि टोल", english: "Kampani Tole", value: "kampani_tole" },
        {
            nepali: "पश्चिम टोल सिबिर",
            english: "Paschim Tole Siwir",
            value: "paschim_tole_siwir",
        },
        { nepali: "दुर्गा टोल", english: "Dhurga Tole", value: "dhurga_tole" },
        { nepali: "महाकाली टोल", english: "Mahakali Tole", value: "mahakali_tole" },
        {
            nepali: "नव शान्ति टोल",
            english: "Nawa Shanti Tole",
            value: "nawa_shanti_tole",
        },
        {
            nepali: "बुद्ध मन्दिर टोल",
            english: "Buddha Mandir Tole",
            value: "buddha_mandir_tole",
        },
        { nepali: "बुद्ध टोल", english: "Buddha Tole", value: "buddha_tole" },
        { nepali: "ए गाउँ", english: "A Gaun", value: "a_gaun" },
        { nepali: "कृष्ण टोल", english: "Krishna Tole", value: "krishna_tole" },
        { nepali: "गुम्बा दण्ड", english: "Gumba Dand", value: "gumba_dand" },
        { nepali: "जी गाउँ", english: "G Gaun", value: "ji_gaun" },
        { nepali: "पोखरी टोल", english: "Pokhari Tole", value: "pokhari_tole" },
        { nepali: "सृजन टोल", english: "Srijan Tole", value: "srijan_tole" },
        { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
        { nepali: "खुटिया टोल", english: "Khutiya Tole", value: "khutiya_tole" },
        { nepali: "हनुमान टोल", english: "Hanumaan Tole", value: "hanumaan_tole" },
        { nepali: "मन्हेरा सिबिर", english: "Manhara Siwir", value: "mnhera_siwir" },
        { nepali: "मन्हेरा", english: "Manhera", value: "mnhera" },
        { nepali: "बसांत टोल", english: "basanta Tole", value: "basan_tole" },
        {
            nepali: "बसन्त टोल सिबिर",
            english: "basant Tole Sibir",
            value: "basaant_tole_sibir",
        },
        { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
        { nepali: "रामनगर", english: "Ram Nagar", value: "ram_nagar" },
        { nepali: "कुनाल टोल", english: "Kunal Tole", value: "kunal_tole" },
        {
            nepali: "सिद्धार्थ टोल",
            english: "Siddharth Tole",
            value: "siddharth_tole",
        },
        { nepali: "बन्देवी टोल", english: "Bendewi Tole", value: "bendewi_tole" },
        { nepali: "बुद्ध टोल", english: "Buddha Tole 1", value: "buddha_tole_1" },
        { nepali: "मदन टोल", english: "Madan Tole", value: "madan_tole" },
        { nepali: "डाण्डा टोल", english: "Danda Tole", value: "danda_tole" },
        { nepali: "राजपुर", english: "Rajpur", value: "rajpur" },
        { nepali: "मोहनपुर", english: "Mohanpur", value: "mohanpur" },
        { nepali: "गेटा", english: "Geta", value: "geta" },
        { nepali: "बर्बत्ता", english: "Barbatta", value: "barbatta" },
        { nepali: "मतियारी टोल", english: "Matiyari Tole", value: "matiyari_tole" },
        { nepali: "मलो मतियार", english: "Mlomatiyar", value: "mlomatiyar" },
        { nepali: "अम्बिका टोल", english: "Ambika Tole", value: "ambika_tole" },
        { nepali: "बोरा डा डी", english: "Bora_dadi", value: "bora_dadi" },
        { nepali: "श्रीलंका", english: "Srilanka", value: "srilanka" },
        { nepali: "कैलाली", english: "Kailali", value: "kailali" },
        { nepali: "झलारी गाउँ", english: "Jhalari Gaun", value: "jhalari_gaun" },
        {
            nepali: "उत्तरे बेहंदी",
            english: "Uttare Behandi",
            value: "uttare_behandi",
        },
        { nepali: "शिवनगर", english: "Shivnagar", value: "shivnagar" },
        { nepali: "दुर्गा टोल", english: "Durga Tole", value: "durga_tole" },
        { nepali: "हिमाली टोल", english: "Himalitole", value: "himalitole" },
        {
            nepali: "हिमाली टोल कैलाली गाउँ",
            english: "Himali Tole Kailali Gaun",
            value: "himali_tole_kailali_gaun",
        },
        { nepali: "गगुटा टोल", english: "Gaguta Tole", value: "gaguta_tole" },
        { nepali: "घगल टोल", english: "Ghagal Tole", value: "ghagal_tole" },
        { nepali: "बि बिस्त टोल", english: "Bi bit Tole", value: "bi_bit_tole" },
        { nepali: "बिस्ट टोल", english: "Bist Tole", value: "bist_tole" },
        { nepali: "बि गाउँ", english: "B gaun", value: "b_gaun" },
        { nepali: "ओझा टोल", english: "Ojha Tole", value: "ojha_tole" },
        { nepali: "बाजुरा टोल", english: "Bajura Tole", value: "bajura_tole" },
        { nepali: "बझांग टोल", english: "Bhajang Tole", value: "bhajang_tole" },
        { nepali: "सलेन टोल", english: "Slen Tole", value: "slen_tole" },
        { nepali: "सी गाउँ", english: "C Gaun", value: "c_gaun" },
        { nepali: "जी गाउँ", english: "G Gaun", value: "g_gaun" },
        { nepali: "डी गाउँ", english: "D Gaun", value: "d_gaun" },
        { nepali: "शिवालय टोल", english: "Shiwalaya Tole", value: "shiwalaya_tole" },
        { nepali: "इ गाउँ", english: "E Gaun", value: "e_gaun" },
        { nepali: "मालपोत रोड", english: "Malpot Road", value: "malpot_road" },
        { nepali: "एच गाउँ", english: "H Gaun", value: "h_gaun" },
        { nepali: "बुद्ध टोल", english: "Buddha Tole", value: "buddha_tole" },
        { nepali: "कुसुम डांडा", english: "Kusum Danda", value: "kusum_danda" },
        { nepali: "लामांग टोल", english: "Lamang", value: "lamang" },
        { nepali: "बलडांडा", english: "Badanda", value: "badanda" },
        { nepali: "खुटिया टोल", english: "Khutiya Tole", value: "khutiya_tole" },
        { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
        { nepali: "देवरिया", english: "Dewriya", value: "dewriya" },
        { nepali: "हरियाली टोल", english: "Hariyali Tole", value: "hariyali_tole" },
        { nepali: "बक्पुर टोल", english: "Bakpur Tole", value: "bakpur_tole" },
        { nepali: "भातरी टोल", english: "Bhatri Tole", value: "bhatri_tole" },
        { nepali: "बन टोल", english: "Ban Tole", value: "ban_tole" },
        { nepali: "स्याउले", english: "Swaule", value: "swaule" },
        { nepali: "डिपो बजार ", english: "Dipobajar", value: "dipobajar" },
        { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
        { nepali: "२६ नम्बर टोल", english: "26 N._tole", value: "26_n._tole" },
        { nepali: "कनरी", english: "Kanari", value: "kanari" },
        { nepali: "कोपिला टोल", english: "Kopila tole", value: "kopila_tole" },
        { nepali: "उर्मा", english: "Urma", value: "urma" },
        { nepali: "उमिर", english: "Umir", value: "umir" },
        { nepali: "तर्बरिया", english: "Trbariya", value: "trbariya" },
        {
            nepali: "उत्तेर बेहंदी",
            english: "Uttare behandi",
            value: "uttare_behandi",
        },
        { nepali: "बेहंदी", english: "behandi", value: "behandi" },
        { nepali: "उर्मा कटान", english: "Urma Katan", value: "urma_katan" },
        { nepali: "सुर्मी कटान", english: "Surmi Katan", value: "surmi_katan" },
        { nepali: "चौधरी टोल", english: "Chaudhari Tole", value: "chaudhari_tole" },
        { nepali: "रामपुर", english: "Rampur", value: "rampur" },
        { nepali: "धशीटा", english: "Dhasita", value: "dhasita" },
        { nepali: "ब्युशाह", english: "Bushhaha", value: "bushhaha" },
        { nepali: "घुराही", english: "Ghurahi", value: "ghurahi" },
        { nepali: "भडा", english: "Bhada", value: "bhada" },
        { nepali: "जल्घुस्रा", english: "Jalghusna", value: "jalghusna" },
        { nepali: "ढाकाटोल", english: "Dhakatole", value: "dhakatole" },
        { nepali: "पथरी", english: "Pathari", value: "pathari" },
        { nepali: "धुझाना", english: "Ghujana", value: "ghujana" },
        { nepali: "धुराही", english: "Ghurahi", value: "ghurahi" },
        { nepali: "दमौलिया", english: "Dmauliya", value: "dmauliya" },
        { nepali: "सरौता", english: "Sarauta", value: "sarauta" },
        { nepali: "बिस्ट टोल", english: "Bist Tole", value: "bist_tole" },
        { nepali: "ए गाउँ", english: "A Gaun", value: "a_gaun" },
        { nepali: "डांडा टोल", english: "Danda Tole", value: "danda_tole" },
        { nepali: "एफ गाउँ", english: "F Gaun", value: "f_gaun" },
        { nepali: "जी गाउँ", english: "G Gaun", value: "g_gaun" },
        { nepali: "अम्खोहिया टोल", english: "Amkohiya Tole", value: "amkohiya_tole" },
        { nepali: "जे गाउँ", english: "J Gaun", value: "j_gaun" },
        { nepali: "तुल्सिबारी", english: "Tulsibari", value: "tulsibari" },
        { nepali: "इ गाउँ", english: "E Gaun", value: "e_gaun" },
        {
            nepali: "लाली गुराँस टोल",
            english: "Laligurans Tole",
            value: "laligurans_tole",
        },
        { nepali: "डिपु", english: "Dipu", value: "dipu" },
        {
            nepali: "स्वर्गद्वारी मार्ग",
            english: "Swargdwari Marg",
            value: "swargdwari_marg",
        },
        { nepali: "डिपो बजार", english: "Dipo Bajar", value: "dipo_bajar" },
        { nepali: "चौधरी टोल", english: "Chaudhari Tole", value: "chaudhari_tole" },
        { nepali: "नुक्लिपुर", english: "Nuklipur", value: "nuklipur" },
        { nepali: "फया मार्ग", english: "Phaya Marg", value: "phaya_marg" },
        { nepali: "गौमती मार्ग", english: "Gaumati Marg", value: "gaumati_marg" },
        { nepali: "गोमती टोल", english: "Gomati Tole", value: "gomati_tole" },
        { nepali: "आदर्श टोल", english: "Aadarsh Tole", value: "aadarsh_tole" },
        { nepali: "खप्तड टोल", english: "Khaptad Tole", value: "khaptad_tole" },
        {
            nepali: "खप्तड टोल, एफ गाउँ",
            english: "Khaptad Tole,_f_gaun",
            value: "khaptad_tole,_f_gaun",
        },
        { nepali: "भाग्वती टोल", english: "Bhagwati Tole", value: "bhagwati_tole" },
        { nepali: "मोहना मार्ग", english: "Mohana Marg", value: "mohana_marg" },
        {
            nepali: "हाइ स्कुल मार्ग",
            english: "Hai School Marg",
            value: "hai_school_marg",
        },
        { nepali: "औधोगिक मार्ग", english: "Audhogik Marg", value: "audhogik_marg" },
        { nepali: "शिव टोल", english: "Shiv Tole", value: "shiv_tole" },
        { nepali: "शिक्षा टोल", english: "Sikshya Tole", value: "sikshya_tole" },
        { nepali: "कालिका टोल", english: "Kalika Tole", value: "kalika_tole" },
        { nepali: "क्याम्पस रोड", english: "Campus Road", value: "campus_road" },
        { nepali: "पशुपति टोल", english: "Pashupati Tole", value: "pashupati_tole" },
        { nepali: "प्रगति टोल", english: "Pragati Tole", value: "pragati_tole" },
        {
            nepali: "मोहनियाल टोल",
            english: "Mohaniyal Tole",
            value: "mohaniyal_tole",
        },
        { nepali: "कृषि मार्ग", english: "Krishi Marg", value: "krishi_marg" },
        { nepali: "आइ गाउँ", english: "I Gaun", value: "i_gaun" },
        { nepali: "कृष्ण नगर", english: "Krishsna Nagar", value: "krishsna_nagar" },
        { nepali: "हिमचुली टोल", english: "Himchuli Tole", value: "himchuli_tole" },
        {
            nepali: "मिम्चुली मार्ग",
            english: "Mimchuli Marg",
            value: "mimchuli_marg",
        },
        { nepali: "जयब्हनु टोल", english: "Jayabhanu Tole", value: "jayabhanu_tole" },
        {
            nepali: "जायभानु मार्ग",
            english: "Jayabhanu Marg",
            value: "jayabhanu_marg",
        },
        { nepali: "इन्द्रदेव टोल", english: "Indradew Tole", value: "indradew_tole" },
        { nepali: "सिता पाथ", english: "Sita Path", value: "sita_path" },
        { nepali: "कान्ति टोल", english: "Kanti Tole", value: "kanti_tole" },
        {
            nepali: "टाउन प्लान बस्ति ",
            english: "Town Plan Basti",
            value: "town_plan_basti",
        },
        { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
        { nepali: "पोख्रेल टोल", english: "Pokhrel Tole", value: "pokhrel_tole" },
        { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
        { nepali: "खेदा", english: "Kheda", value: "kheda" },
        { nepali: "बहुलिया", english: "Bahuliya", value: "bahuliya" },
        { nepali: "भुलिया", english: "Bhuliya", value: "bhuliya" },
        {
            nepali: "जे गाउँडांडा टोल",
            english: "J Gaundada Tole",
            value: "j_gaundada_tole",
        },
        { nepali: "औधोगिक मार्ग", english: "Audhogik Marg", value: "audhogik_marg" },
        { nepali: "आइ गाउँ", english: "I Gaun", value: "i_gaun" },
        {
            nepali: "टाउन प्लान बस्ति",
            english: "Taun Plan Basti",
            value: "taun_plan_basti",
        },
        { nepali: "इ गाउँ", english: "I Gaun 1", value: "i_gaun_1" },
        { nepali: "चाडर टोल", english: "Chadar Tole", value: "chadar_tole" },
        { nepali: "कालिका टोल", english: "Kalika Tole", value: "kalika_tole" },
        { nepali: "फुलबारी ", english: "Phulbari 1", value: "phulbari_1" },
        { nepali: "नया मार्ग", english: "Naya Marg", value: "naya_marg" },
        { nepali: "जे गाउँ", english: "J Gaun", value: "j_gaun" },
        { nepali: "स्कुल मार्ग", english: "Skul Marg", value: "skul_marg" },
        { nepali: "गौक्न्दारी", english: "Gaukndari", value: "gaukndari" },
        { nepali: "जे गाउँ १ ", english: "J Gaun 1", value: "j_gaun_1" },
        { nepali: "जे गाउँ ३", english: "J Gaun 3", value: "j_gaun_3" },
        { nepali: "चौरि", english: "Chauri", value: "chauri" },
        { nepali: "जे गाउँ ४", english: "J Gaun 4", value: "j_gaun_4" },
        { nepali: "ललितपुर", english: "Lalitpur", value: "lalitpur" },
        { nepali: "कृष्ण नगर", english: "Krishna Nagar", value: "krishna_nagar" },
        { nepali: "सुन्दर शक्ति", english: "Sundar Shakti", value: "sundar_shakti" },
        { nepali: "स्कुल मार्ग १", english: "Skul Marg 1", value: "skul_marg_1" },
        {
            nepali: "आइ गाउँ स्कुल मार्ग २",
            english: "I Gaun Skul Marg 2",
            value: "i_gaun_skul_marg_2",
        },
        { nepali: "देवी मन्दिर", english: "Dewi Mandir", value: "dewi_mandir" },
        { nepali: "रप्तौनी", english: "Rptauni", value: "rptauni" },
        { nepali: "गौचरण १", english: "Gaucharan 1", value: "gaucharan_1" },
        { nepali: "गौचरण २", english: "Gaucharan 2", value: "gaucharan_2" },
        { nepali: "निम्पुर मार्ग", english: "Nimpur Marg", value: "nimpur_marg" },
        {
            nepali: "दक्षिण लालिपुर",
            english: "Dakshin Laalipur",
            value: "dakshin_laalipur",
        },
        { nepali: "बोडर टोल", english: "Border Tole", value: "border_tole" },
        { nepali: "बोर्दंदी", english: "Bordadi", value: "bordadi" },
        { nepali: "कुत्ति टोल", english: "Kutti Tole", value: "kutti_tole" },
        { nepali: "मगराति मार्ग", english: "Magarati Marg", value: "magarati_marg" },
        {
            nepali: "बाल सिर्जना १ ",
            english: "Baal Sirjana 1",
            value: "baal_sirjana_1",
        },
        { nepali: "बाल सिर्जना २", english: "Bal Sirjana 2", value: "bal_sirjana_2" },
        { nepali: "पहाडी टोल", english: "Pahadi Tole", value: "pahadi_tole" },
        { nepali: "मोहना मार्ग", english: "Mohana Marg", value: "mohana_marg" },
        { nepali: "खेदा १", english: "Kheda 1", value: "kheda_1" },
        { nepali: "खेदा २", english: "Khelda 2", value: "khelda_2" },
        { nepali: "खेदा ३", english: "Khelda 3", value: "khelda_3" },
        { nepali: "खेदा ४", english: "Khelda 4", value: "khelda_4" },
        { nepali: "खेदा ५", english: "Khelda 5", value: "khelda_5" },
        { nepali: "खेदा ६", english: "Khelda 6", value: "khelda_6" },
        { nepali: "खेदा ७", english: "Khelda 7", value: "khelda_7" },
        { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
        { nepali: "भूपु", english: "Bhu Pu", value: "bhu_pu" },
        { nepali: "रानीपोखरी", english: "Ranipokhari", value: "ranipokhari" },
        { nepali: "सान्घारी", english: "Singhari", value: "singhari" },
        { nepali: "२६ नम्बर टोल", english: "26 no. Tole", value: "26_no._tole" },
        { nepali: "ल्ग्गा भागगा", english: "Lgga Bhagga", value: "lgga_bhagga" },
        { nepali: "बाइ पास रोड", english: "Baipass Road", value: "baipass_road" },
        { nepali: "सहकारी रोड", english: "Sahakari Road", value: "sahakari_road" },
        { nepali: "सिविल रोड", english: "Civil Road", value: "civil_road" },
        { nepali: "बि गाउँ", english: "B Gaun", value: "b_gaun" },
        {
            nepali: "१५ मिटर मद्यावती टोल",
            english: "15 Miter Mddwati Tole",
            value: "15_miter_mddwati_tole",
        },
        { nepali: "सिमा वर्ति", english: "Sima Warti", value: "sima_warti" },
        { nepali: "उर्जा अवासाय", english: "Urja Awasaya", value: "urja_awasaya" },
        { nepali: "बेहंदी", english: "Behandi", value: "behandi" },
        {
            nepali: "रामजानकी टोल",
            english: "Ramjanaki Tole",
            value: "ramjanaki_tole",
        },
        { nepali: "उत्तम नगर", english: "Uttam Nagar", value: "uttam_nagar" },
        { nepali: "बस पार्क", english: "Bus Park", value: "bus_park" },
        { nepali: "कोपिला टोल", english: "Kopila Tole", value: "kopila_tole" },
        { nepali: "शान्ति टोल", english: "Shanti Tole 1", value: "shanti_tole_1" },
        { nepali: "अन्य", english: "Others", value: "others" },
    ];

export const toleWardOne = [
    { nepali: "पशुपति टोल", english: "Pashupati Tole", value: "pashupati_tole" },
    { nepali: "सरस्वति टोल", english: "Saraswoti Tole", value: "saraswoti_tole" },
    { nepali: "जोशी टोल", english: "Joshi Tole", value: "joshi_tole" },
    { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
    { nepali: "कृष्ण टोल", english: "Krishna tole", value: "krishna_tole" },
    {
        nepali: "मनकामना टोल, जलि",
        english: "Manakmana Tole",
        value: "manakmana_tole",
    },
    { nepali: "मनकामना टोल", english: "manamaka-tole", value: "manamaka-tole" },
    { nepali: "भवानी टोल", english: "Babani Tole", value: "bhabani-_tole" },
    {
        nepali: "शान्तिनगर टोल",
        english: "Santingar Tole",
        value: "santingar-_tole",
    },
    { nepali: "कैलाली पुल", english: "kailali tole", value: "kailali-tole" },
    { nepali: "त्रिवेणी चोक", english: "Tribani chowk", value: "tribani-chowk" },
    { nepali: "प्रगति टोल", english: "Pragati Tole", value: "pragati-tole" },
    { nepali: "नवदुर्गा टोल", english: "Nawadurga tole", value: "nawadurga" },
    { nepali: "लक्ष्मी नगर", english: "laxmi nagar", value: "laxmi_nagar" },
    {
        nepali: "पर्शिराम टोल",
        english: "Parshiram tole",
        value: "parshiram_tole",
    },
    { nepali: "ओल्ड एरपोर्ट", english: "Old Airport", value: "old_airport" },
    { nepali: "नैनादेवी टोल", english: "Nainadewi tole", value: "nainadewi_tol" },
    {
        nepali: "सृजनसिल टोल",
        english: "Srijansil tole",
        value: "srijan_sil_tole",
    },
    { nepali: "विनायक टोल", english: "Winayak tol", value: "winayak_tol" },
    { nepali: "अल एन चोक", english: "Alen chok", value: "alen_chok" },
    { nepali: "स्वर टोल", english: "Swar tole", value: "swar_tole" },
    { nepali: "शिव नगर", english: "Shivanagar", value: "shiv_nagar" },
    { nepali: "फाय मार्ग", english: "Phaya marg", value: "phaya_marg" },
    {
        nepali: "जयलक्ष्मी टोल",
        english: "Jayalaxmi tole",
        value: "jayalaxmi_tole",
    },
    {
        nepali: "मनिकपुर सिबिर",
        english: "Manikpur siwir",
        value: "manikpur_siwir",
    },
    { nepali: "मालपोत रोड", english: "Maalpot road", value: "maalpot_road" },
    { nepali: "पलाई वुड टोल", english: "Plywood tole", value: "ply_wood_tole" },
    { nepali: "सेतो पुल", english: "Setopul", value: "seto_pul" },
    { nepali: "पार्क मोड", english: "Paarkmod", value: "paark_mod" },
    { nepali: "हस्नपुर", english: "Hanspur", value: "hanspur" },
    { nepali: "बनदेवी", english: "Wandewi", value: "wandewi" },
    { nepali: "टोल", english: "Tole", value: "tole" },
    { nepali: "अदालत रोड", english: "Adalat road", value: "adalat_road" },
    { nepali: "बसन्त टोल", english: "Basant tole", value: "basant_tole" },
    { nepali: "NTC रोड", english: "ntc road", value: "ntc_road" },
    {
        nepali: "राधा कृष्ण टोल",
        english: "Radhakrishna tole",
        value: "radha_krishna_tole",
    },
    {
        nepali: "श्री गणेश टोल",
        english: "shreeganesh tole",
        value: "shree_ganesh_tole",
    },
    { nepali: "पृथ्वी टोल", english: "Prithwi tole", value: "prithwi_tole" },
    { nepali: "मंगलसेन टोल", english: "Mangalsen tole", value: "mangalsen_tole" },
    {
        nepali: "बसन्त टोल सिबिर",
        english: "Basant tole sibir",
        value: "basant_tole_1",
    },
    {
        nepali: "हरियाली टोल सिबिर",
        english: "Hariyali tole sibir",
        value: "hariyali_tole",
    },
    { nepali: "हरियाली टोल", english: "hariyali tole", value: "hariyali_tole_1" },
    { nepali: "आनन्द टोल", english: "Aanand tole", value: "aanand_tole" },
    {
        nepali: "शैलेश्वरी टोल",
        english: "Shaileswari tole",
        value: "shaileswari_tole",
    },
    { nepali: "मजिस्द रोड", english: "Masjid road", value: "masjid_road" },
    {
        nepali: "मिश्रा प्रिन्टर्स रोड",
        english: "Misra prints road",
        value: "misra_prints_road",
    },
    { nepali: "होस्पितल लाइन", english: "Hospital line", value: "hospital_line" },
    { nepali: "हनुमान टोल", english: "Hanumaantole", value: "hanumaan_tole" },
    { nepali: "पिपल चोक", english: "Pipal chok", value: "pipal_chok" },
    { nepali: "डिल्ली बजार", english: "Dilli bajar", value: "dilli_bajar" },
    { nepali: "मेन रोड", english: "Main road", value: "main_road" },
    { nepali: "बुद्ध टोल", english: "Buddha tole", value: "buddha_tole" },
    { nepali: "बुद्ध चोक", english: "Buddha chok", value: "buddha_chok" },
    { nepali: "साइराम टोल", english: "Sairam tole", value: "sai_ram_tole" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardTwo = [
    { nepali: "चौराहा", english: "chauraha", value: "chauraaha" },
    { nepali: "मेनरोड", english: "Mainroad", value: "mainroad" },
    { nepali: "बैया बेहेदी", english: "baiya Behedi", value: "baiya_behedi" },
    { nepali: "रतो पुल", english: "Rato pul", value: "rato_pul" },
    { nepali: "कैलाली पुल", english: "kailaali pul", value: "kailaali_pul" },
    { nepali: "सन्तोषी टोल", english: "santoshi tole", value: "santoshi_tole" },
    {
        nepali: "सरस्वति नगर",
        english: "Saraswati nagar",
        value: "saraswati_nagar",
    },
    { nepali: "नया बस्ति", english: "Naya basti", value: "naya_basti" },
    { nepali: "कृष्ण नगर", english: "Krishna nagar", value: "krishna_nagar" },
    {
        nepali: "सिता पथ, चौरहा",
        english: "Sitapath,chauraha",
        value: "sita_path,_chauraha",
    },
    { nepali: "मलिका टोल", english: "Malika tole", value: "malika_tole" },
    { nepali: "शिखर टोल", english: "Shikhar tole", value: "shikhar_tole" },
    { nepali: "मिलन टोल", english: "Milan tole", value: "milan_tole" },
    { nepali: "लिल्के टोल", english: "Lilke tole", value: "lilke_tole" },
    { nepali: "आदर्श टोल", english: "Aadarsh tole", value: "aadarsh_tole" },
    {
        nepali: "पुरानो भन्सार रोड",
        english: "Puranobhansar road",
        value: "purano_bhansar_road",
    },
    { nepali: "कालिका टोल", english: "Kalika tole", value: "kalika_tole" },
    {
        nepali: "राम जानकी टोल",
        english: "Ramjanaki tole",
        value: "ram_janaki_tole",
    },
    { nepali: "राम नगर", english: "Ram nagar", value: "ram_nagar" },
    { nepali: "नवादिप टोल", english: "Nawadip tole", value: "nawadip_tole" },
    {
        nepali: "सिद्धार्थ टोल",
        english: "Siddharth tole",
        value: "siddharth_tole",
    },
    {
        nepali: "सिंगापुरे टोल",
        english: "Singapuree tole",
        value: "singapuree_tole",
    },
    {
        nepali: "कृष्ण नगर, चौराहा",
        english: "krishna nagar, chauraha",
        value: "krishna_nagar_1",
    },
    { nepali: "कृष्ण नगर", english: "krishna nagar", value: "krishna_nagar_2" },
    { nepali: "रैका टोल", english: "Raika tole", value: "raika_tole" },
    { nepali: "बैदनाथ टोल", english: "Baidnaath tole", value: "baidnaath_tole" },
    { nepali: "एभरेस्ट टोल", english: "Everest tole", value: "everest_tole" },
    { nepali: "श्रीलंका", english: "Srilanka", value: "srilanka" },
    { nepali: "लागृति टोल", english: "Lagriti tole", value: "lagriti_tole" },
    { nepali: "भवानी टोल", english: "Bhawani tole", value: "bhawani_tole" },
    { nepali: "भन्सार", english: "Bhansaar", value: "bhansaar" },
    { nepali: "भन्सार रोड", english: " Bhansaar road", value: "bhansaar_road" },
    { nepali: "मिलन चोक", english: "Milan chowk", value: "milan_chowk" },
    { nepali: "अन्य", english: "others", value: "others" },
];

export const toleWardThree = [
    {
        nepali: "भन्सार-राजपुर",
        english: "Bhansaar raajpur",
        value: "bhansaar_raajpur",
    },
    { nepali: "मिलन मार्ग", english: "Milan marg", value: "milan_marg" },
    { nepali: "चटकपुर", english: "Chatakpur", value: "chatakpur" },
    { nepali: "भन्सार", english: "bhansar", value: "bhansar" },
    { nepali: "मिलन टोल", english: "Milantole", value: "milantole" },
    { nepali: "केराबारी", english: "Kera bari", value: "kera_bari" },
    { nepali: "बडारा गाउँ", english: "Bedara Gaun", value: "bdara_gaun" },
    { nepali: "मिलन चोक", english: "Milan chowk", value: "milan_chowk" },
    { nepali: "डीपन्द्र टोल", english: "Dipendra tole", value: "dipendra_tole" },
    { nepali: "बद्र गाउँ", english: "Badra gaun", value: "badra_gaun" },
    { nepali: "बधारा", english: "Badhara", value: "badhara" },
    { nepali: "विशालनगर", english: "Bishalnagar", value: "bishalnagar" },
    { nepali: "भैरव टोल", english: "Bhairaw tole", value: "bhairaw_tole" },
    { nepali: "बोराडन्डी", english: "Worodandi", value: "worodandi" },
    { nepali: "गुरुद्वार", english: "Gurudwar", value: "gurudwar" },
    { nepali: "बेहांदी", english: "Bihandi", value: "bihandi" },
    { nepali: "कचण टोल", english: "Kachan tole", value: "kachan_tole" },
    { nepali: "जनता मार्ग", english: "Janata marg", value: "janata_marg" },
    { nepali: "डिलासैनी", english: "Dilasaini", value: "dilasaini" },
    { nepali: "सिद्धिविनायक", english: "Siddibinayak", value: "siddibinayak" },
    { nepali: "बोरादंदी", english: "Boradandi", value: "boradandi" },
    { nepali: "गुरुद्वार", english: "Gurudwar", value: "gurudwar_1" },
    {
        nepali: "त्रिभुवन मार्ग",
        english: "Tribhuwan marg",
        value: "tribhuwan_marg",
    },
    { nepali: "केदार", english: "Kedar", value: "kedar" },
    { nepali: "बोराडाडी", english: "Boradadi", value: "boradadi" },
    { nepali: "सोडन्डी", english: "Sodandi", value: "sodandi" },
    {
        nepali: "धनगढी हाइवे साइड",
        english: "Bhimdatt highway side",
        value: "bhimdatt_haighway_said",
    },
    { nepali: "भगवती टोल", english: "Bhagwati tole", value: "bhagwati_tole" },
    { nepali: "चौराह", english: "Chauraha", value: "chauraha" },
    { nepali: "राजपुर", english: "Rajpur", value: "rajpur" },
    { nepali: "अन्य", english: "others", value: "others" },
];

export const toleWardFour = [
    { nepali: "चौराहा", english: "Chauraha", value: "chauraha" },
    { nepali: "बस पार्क", english: "Buspark", value: "buspark" },
    { nepali: "कैलाली पुल", english: "Kailai pul", value: "kailai_pul" },
    { nepali: "कैलाली गाउँ", english: "Kailali gaun", value: "kailali_gaun" },
    { nepali: "बोराडन्डी", english: "Boradandi", value: "boradandi" },
    { nepali: "उत्तर बेहंदी", english: "Uttar behandi", value: "uttar_behandi" },
    { nepali: "तारा नगर", english: "Taara nagar", value: "taara_nagar" },
    {
        nepali: "ॐ शान्ति टोल ",
        english: "Om shanti tole",
        value: "om_shanti_tole",
    },
    {
        nepali: "खानेपानी टोल",
        english: "khanepani tole",
        value: "khanepani_tole",
    },
    { nepali: "बेह्दी", english: "Behandi", value: "behandi" },
    { nepali: "चटकपुर", english: "Chatakpur", value: "chatakpur" },
    { nepali: "बेहंदी", english: "Behaandi", value: "behandi_1" },
    { nepali: "अन्य", english: "others", value: "others" },
];

export const toleWardFive = [
    { nepali: "शिवनगर", english: "Shibnagar", value: "shibnagar" },
    { nepali: "जय टोल", english: "Jayatole", value: "jayatole" },
    { nepali: "भैरब टोल", english: "Bhairab tole", value: "bhairab_tole" },
    {
        nepali: "उत्तरे बेहंदी",
        english: "Uttare behandi",
        value: "uttare_behandi",
    },
    { nepali: "तारा नगर", english: "Tara nagar", value: "tara_nagar" },
    { nepali: "हस्नपुर", english: "Hanspur", value: "hanspur" },
    { nepali: "ज्योति नगर", english: "Jyoti nagar", value: "jyoti_nagar" },
    { nepali: "भैरब टोल", english: "Bhairab tole 1", value: "bhairab_tole_1" },
    { nepali: "नमुना टोल", english: "Namuna tole", value: "namuna_tole" },
    {
        nepali: "नमुना टोल, तारानगर",
        english: "Namuna tole taranagar",
        value: "namuna_tole_taranagar",
    },
    {
        nepali: "जय आभास नगर टोल",
        english: "Jayaaabhas nagar tole",
        value: "jaya_aabhas_nagar_tole",
    },
    { nepali: "जोइ टोल", english: "Joi tole", value: "joi_tole" },
    {
        nepali: "गौरश्सोर टोल",
        english: "Gaureshsor tole",
        value: "gaureshsor_tole",
    },
    { nepali: "दाहाल टोल", english: "Dahal tole", value: "dahal_tole" },
    { nepali: "गणेस टोल", english: "Ganesh tole", value: "ganesh_tole" },
    {
        nepali: "मनकामना टोल",
        english: "Manakamana tole",
        value: "manakamana_tole",
    },
    { nepali: "कैलाश टोल", english: "Kailash tole", value: "kailash_tole" },
    { nepali: "प्रभात टोल", english: "Prabhat tole", value: "prabhat_tole" },
    { nepali: "प्रभाब टोल", english: "Prabhab tole", value: "prabhab_tole" },
    { nepali: "सद्भाव टोल", english: "Sadbhab tole", value: "sadbhab_tole" },
    {
        nepali: "सिद्धि गणेश टोल,जाली",
        english: "Siddhiganesh tole,Jaali",
        value: "siddhi_ganesh_tole,_jaali",
    },
    { nepali: "अन्य", english: "others", value: "others" },
];

export const toleWardSix = [
    { nepali: "भैरब टोल", english: "Bhairab tole", value: "bhairab_tole" },
    { nepali: "बिजुलिया गाउँ", english: "Bijuliya gaun", value: "bijuliya_gaun" },
    {
        nepali: "शान्ति टोल , जाली",
        english: "Shanti tole, jaali",
        value: "shanti_tole,_jaali",
    },
    {
        nepali: "पिपल चोक, जाली",
        english: "Pipal chok, jaali",
        value: "pipal_chok,_jaali",
    },
    {
        nepali: "जव दुर्गा टोल, जली",
        english: "Jawa durga tole, jali",
        value: "jawa_durgaa_tole,_jali",
    },
    {
        nepali: "मनकामना टोल",
        english: "Manakamana tole",
        value: "manakamana_tole",
    },
    { nepali: "हरियाली टोल", english: "Hariyali tole", value: "hariyali_tole" },
    { nepali: "शिव टोल", english: "Shiv tole", value: "shiv_tole" },
    { nepali: "सालघारी", english: "Salkhari", value: "salkhari" },
    { nepali: "पपुलर", english: "Popular", value: "papular" },
    { nepali: "कृष्ण टोल", english: "Krishna tole", value: "krishna_tole" },
    { nepali: "कृष्ण नगर", english: "krishna nagar", value: "krishna_nagar" },
    { nepali: "शान्ति टोल", english: "Shanti tole", value: "shanti_tole" },
    {
        nepali: "शान्ति टोल, म्त्यारी",
        english: "Shanti tole, mnyaari",
        value: "shanti_tole,_mnyaari",
    },
    { nepali: "ढुंगे बजार", english: "Dhunge bajar", value: "dhunge_bajar" },
    { nepali: "डी गाउँ", english: "D gaun", value: "d_gaun" },
    {
        nepali: "श्री कृष्णटोल",
        english: "shree krishna tole",
        value: "shree_krishna_tole",
    },
    { nepali: "भैरव टोल", english: "Bhairab tole", value: "bhairab_tole_1" },
    { nepali: "जै टोल", english: "Jai tole", value: "jai_tole" },
    { nepali: "नमुना टोल", english: "Namuna tole", value: "namuna_tole" },
    { nepali: "नमुना टोल", english: "Namuna tole", value: "namuna_tole_1" },
    { nepali: "बिर चोक", english: "Bir chowk", value: "bir_chowk" },
    { nepali: "तारानगर", english: "Taranagar", value: "taranagar" },
    { nepali: "प्रभात टोल", english: "Prabhat tole", value: "prabhat_tole" },
    {
        nepali: "बिस्वनाथ टोल",
        english: "Biswanath tole",
        value: "biswanath_tole",
    },
    { nepali: "बिजुलिया", english: "Bijuliya", value: "bijuliya" },
    { nepali: "बसन्त टोल", english: "Basaant tole", value: "basaant_tole" },
    { nepali: "सिद्धि गणेश", english: "Siddhi ganesh", value: "siddhi_ganesh" },
    {
        nepali: "सिद्धार्थ टोल",
        english: "Siddharth tole",
        value: "siddharth_tole",
    },
    { nepali: "शिवनगर", english: "shiv nagar", value: "shiv_nagar" },
    {
        nepali: "दोघरा टोल, बिजुलिया",
        english: "Todhara tole,bijuliya",
        value: "todhara_tole,_bijuliya",
    },
    {
        nepali: "डोघर टोल , जाली",
        english: "Doghar_tole,jaal",
        value: "digharaa_tole,_jaal",
    },
    { nepali: "सुर्योदय", english: "Suryodaya", value: "suryodaya" },
    { nepali: "गुराह टोल", english: "Guraha tole", value: "guraha_tole" },
    {
        nepali: "श्री लक्ष्मी टोल",
        english: "Shree laxmi tole",
        value: "shree_laxmi_tole",
    },
    { nepali: "जागृति टोल", english: "Jaagriti tole", value: "jaagriti_tole" },
    {
        nepali: "उत्तरे बेहंदी",
        english: "Uttare behandi",
        value: "uttare_behandi",
    },
    { nepali: "धनगढी टोल", english: "Dhangadhi tole", value: "dhangadhi_tole" },
    {
        nepali: "नमुना टोल, मत्यारी",
        english: "Namuna tole, matyari",
        value: "namuna_tole,_matyari",
    },
    { nepali: "कि सिंह टोल", english: "Ki Sing Tole", value: "ki..sing._tole" },
    { nepali: "नाजारिया", english: "Najariya", value: "najariya" },
    { nepali: "पंच्कोती", english: "Panchakoti", value: "panchakoti" },
    { nepali: "बुद्ध टोल", english: "Buddhatole", value: "buddha_tole" },
    { nepali: "संगम टोल", english: "sangam tole", value: "sangam_tole" },
    { nepali: "मोहनियल टोल", english: "Mohaniyal tole", value: "mohaniyal_tole" },
    {
        nepali: "नाजारिया टोल, मत्यारी",
        english: "Najariya tole, manyari",
        value: "najariya_tole,_manyari",
    },
    {
        nepali: "प्रगतिशील टोल",
        english: "Pratishiltole",
        value: "pratishil_tole",
    },
    { nepali: "बेला", english: "Bela", value: "bela" },
    { nepali: "लेकाली गाउँ", english: "Lekali gaun", value: "lekali_gaun" },
    { nepali: "हिमाली टोल", english: "Himali tole", value: "himali_tole" },
    { nepali: "अन्य", english: "others", value: "others" },
];

export const toleWardSeven = [
    { nepali: "पतेला", english: "Patala", value: "patala" },
    { nepali: "बेली", english: "Beli", value: "beli" },
    { nepali: "बेला", english: "Bela", value: "bela" },
    { nepali: "डेवि मन्दिर", english: "Dewi Mandir", value: "dewi_mandir" },
    { nepali: "देवनागर टोल", english: "Dewnagar Tole", value: "dewnagar_tole" },
    { nepali: "पचली पट्टि", english: "Pchali patti", value: "pchali_patti" },
    {
        nepali: "अग्लिपत्ति टोल",
        english: "Aglipatti Tole",
        value: "aglipatti_tole",
    },
    {
        nepali: "सरस्वाती टोल",
        english: "Saraswati Tole",
        value: "saraswati_tole",
    },
    { nepali: "पश्चिम टोल", english: "Paschim Tole", value: "paschim_tole" },
    { nepali: "मन्हेरा", english: "Manhera", value: "manhera" },
    { nepali: "सिर्जना टोल", english: "Sirjana Tole", value: "sirjana_tole" },
    {
        nepali: "राम भगत टोल",
        english: "Ram bhagat tole",
        value: "ram_bhagat_tole",
    },
    { nepali: "गौरी टोल", english: "Gauri tole", value: "gauri_tole" },
    { nepali: "मन्हेरा सिबिर", english: "Manhera Sibir", value: "manhera_sibir" },
    {
        nepali: "सिर्पत्ति-बगिया",
        english: "Sirptti Bagiya",
        value: "sirptti_bagiya",
    },
    { nepali: "पश्चिम टोल", english: "Paschim Tole1", value: "paschim_tole_1" },
    { nepali: "सिमल टोल", english: "Simal Tole", value: "simal_tole" },
    { nepali: "गौरी टोल", english: "Gauri Tole 1", value: "gauri_tole_1" },
    {
        nepali: "गौरी टोल , मन्हेरा सिबिर",
        english: "GauriTole Mnhera Sibir",
        value: "gauri_tole,_mnhera_sibir",
    },
    { nepali: "टिलकी टोल", english: "Tilki Tole", value: "tilki_tole" },
    {
        nepali: "शान्तिनगर टोल",
        english: "Shanti Nagar Tole",
        value: "shanti_nagar_tole",
    },
    { nepali: "क्याम्पस रोद", english: "Campus Road", value: "campur_road" },
    { nepali: "गौरी टोल", english: "Gauri Tole 2", value: "gauri_tole_2" },
    { nepali: "जोखर टोल", english: "Jokhar Tole", value: "jokhar_tole" },
    { nepali: "डेबरिय", english: "Debariya", value: "debariya" },
    {
        nepali: "सरस्वति टोल",
        english: "Saraswati Tole 1",
        value: "saraswati_tole_1",
    },
    {
        nepali: "आशा नगर टोल",
        english: "Aasha Nagar Tole",
        value: "aasha_nagar_tole",
    },
    { nepali: "जखौर टोल", english: "Jokhaur Tole", value: "jokhaur_tole" },
    { nepali: "कालिका टोल", english: "Kaalika Tole", value: "kaalika_tole" },
    { nepali: "नमुना टोल", english: "Namuna Tole", value: "namuna_tole" },
    { nepali: "अनामनगर", english: "Anamnagar", value: "anamnagar" },
    { nepali: "मोति चोक", english: "Moti Chowk", value: "moti_chowk" },
    { nepali: "भानु चोक", english: "Bhanu Chowk", value: "bhanu_chowk" },
    { nepali: "हेकुली टोल", english: "Hekuli Tole", value: "hekuli_tole" },
    {
        nepali: "जनज्योति टोल",
        english: "Janajyoti Tole",
        value: "janajyoti_tole",
    },
    { nepali: "मानिया टोल", english: "Maaniya Tole", value: "maaniya_tole" },
    { nepali: "पृथ्वी टोल", english: "Prithwi Tole", value: "prithwi_tole" },
    {
        nepali: "दक्षिण काली टोल",
        english: "Dakshin Kali Tole",
        value: "dakshin_kali_tole",
    },
    {
        nepali: "दक्षिणपट्टि टोल",
        english: "Dakshin Patti Tole",
        value: "dakshin_patti_tole",
    },
    {
        nepali: "सद्ब बाबा टोल",
        english: "Sadda Baba Tole",
        value: "dad_baba_tole",
    },
    { nepali: "आदर्शटोल", english: "Darsh Tole", value: "darsh_tole" },
    {
        nepali: "सरुवा बाबा टोल",
        english: "Saruwaa Baba Tole",
        value: "saruwaa_baba_tole",
    },
    { nepali: "लम्बा टोल", english: "Lamba Tole", value: "lamba_tole" },
    {
        nepali: "पचार पट्टि टोल",
        english: "Pchar Patti Tole",
        value: "pchar_patti_tole",
    },
    { nepali: "बुद्ध चोक", english: "Buddha Chowk", value: "buddha_chowk" },
    { nepali: "अन्य", english: "others", value: "others" },
];

export const toleWardEight = [
    { nepali: "सेतोपुल", english: "Setopul", value: "setopul" },
    { nepali: "क्यम्पुस रोद", english: "Campus Road", value: "campur_road" },
    { nepali: "एसोर्य टोल", english: "Aisosrya Tole", value: "aisosrya_tole" },
    { nepali: "मनेहारा", english: "Manehara", value: "mnehara" },
    { nepali: "सरस्वति टोल", english: "Saraswati Tole", value: "saraswati_tole" },
    {
        nepali: "सरस्वति टोल, मनेहारा",
        english: "Saraswati Tole,Manehara",
        value: "saraswati_tole,_manehara",
    },
    { nepali: "सम्झना टोल", english: "Samjhana Tole", value: "samjhana_tole" },
    { nepali: "कान्तिपुर टोल", english: "Kantipur Tole", value: "kantipur_tole" },
    { nepali: "मोति चोक", english: "Moti Chowk", value: "moti_chowk" },
    {
        nepali: "सद्भावना टोल",
        english: "Sadbhawana Tole",
        value: "sadbhawana_tole",
    },
    { nepali: "सत्कर मार्ग", english: "Satkar Marg", value: "satkar_marg" },
    {
        nepali: "शिक्ष्यानगर टोल",
        english: "Sikshya Nagr Tole",
        value: "sikshya_nagr_tole",
    },
    { nepali: "क्यम्पुस रोड", english: "Campus Road1", value: "campur_road_1" },
    { nepali: "सस्कार मार्ग", english: "Sanskar Marg", value: "sanskar_marg" },
    { nepali: "शिक्षा मार्ग", english: "Sikshya Marg", value: "sikshya_marg" },
    { nepali: "सभ्यता टोल", english: "Sabhyata Tole", value: "sabhyata_tole" },
    { nepali: "अयोद्या मार्ग", english: "Ayodhya Marg", value: "ayodhya_marg" },
    {
        nepali: "अपि सैपाल टोल",
        english: "Api Saipal Tole",
        value: "api_laipal_tole",
    },
    { nepali: "श्रीस्ती टोल", english: "Shreesti Tole", value: "shreesti_tole" },
    { nepali: "रामनगर", english: "Raamnagar", value: "raamnagar" },
    { nepali: "शिवनगर", english: "Shiv Nagar", value: "shiv_nagar" },
    { nepali: "बुद्ध टोल", english: "Buddhaa Tole", value: "buddhaa_tole" },
    { nepali: "विद्यानगर ", english: "Widhyanagar", value: "widhyanagar" },
    { nepali: "उत्टर बेहंदी", english: "Uttar Behandi", value: "uttar_behandi" },
    { nepali: "अनामनगर", english: "Anamnagar", value: "anamnagar" },
    { nepali: "प्रगति टोल", english: "Pragati Tole", value: "pragati_tole" },
    { nepali: "धनगढी गाउँ", english: "Dhangahi Gaun", value: "dhangahi_gaun" },
    {
        nepali: "राधा कृष्ण टोल",
        english: "Radha Krishna Tole",
        value: "radha_krishna_tole",
    },
    {
        nepali: "राम जानकी टोल",
        english: "Ram Janaki Tole",
        value: "ram_janaki_tole",
    },
    {
        nepali: "शुभकामना टोल",
        english: "Shuvakamana Tole",
        value: "shuvakamana_tole",
    },
    { nepali: "शिव टोल", english: "Shiv Tole", value: "shiv_tole" },
    { nepali: "सिमाना टोल", english: "Simana Tole", value: "simana_tole" },
    { nepali: "भाषा टोल", english: "Bhasa Tole", value: "bhasa_tole" },
    {
        nepali: "नवदुर्गा टोल",
        english: "Nawadurga Tole",
        value: "nawadurga_tole",
    },
    {
        nepali: "रह स्वामी टोल",
        english: "Raha SwamiTole",
        value: "raha_swami_tole",
    },
    { nepali: "मुस्कान टोल", english: "Muskan Tole", value: "muskan_tole" },
    { nepali: "भद्र टोल", english: "Bhadra Tole", value: "bhadra_tole" },
    { nepali: "मिलन टोल", english: "Milan Tole", value: "milan_tole" },
    { nepali: "भद्र टोल", english: "Bhadra Tole 1", value: "bhadra_tole_1" },
    { nepali: "मिलन टोल", english: "Milan Tole 1", value: "milan_tole_1" },
    { nepali: "संगम टोल", english: "Sangam Tole", value: "sangam_tole" },
    { nepali: "गणेश टोल", english: "Ganesh Tole", value: "ganesh_tole" },
    {
        nepali: "मन्हेरा सिबिर ",
        english: "Manhera Siwir",
        value: "manhera_siwir",
    },
    { nepali: "बुद्ध चोक", english: "Buddha Chowk", value: "buddha_chowk" },
    { nepali: "अन्य", english: "others", value: "others" },
];

export const toleWardNine = [
    { nepali: "शिवनगर ", english: "Shiv Nagar", value: "shiv_nagar" },
    { nepali: "श्री रामनगर", english: "Shree Ramnagar", value: "shree_ramnagar" },
    { nepali: "ब्न्देवी टोल", english: "Bndewi Tole", value: "bndewi_tole" },
    { nepali: "बांग्र कटान", english: "Banga Katan", value: "banga_katan" },
    { nepali: "सरस्वति टोल", english: "Saraswati Tole", value: "saraswati_tole" },
    { nepali: "हिव टोल", english: "Hib Tole", value: "hib_tole" },
    { nepali: "सिमना टोल", english: "Simana Tole", value: "simana_tole" },
    { nepali: "धनगढी गाउँ", english: "Dhangadhi Gaun", value: "dhangadhi_gaun" },
    { nepali: "भगवती टोल", english: "Bhagwati Tole", value: "bhagwati_tole" },
    { nepali: "बांग्रा", english: "Bangna", value: "bangna" },
    { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
    { nepali: "हरियाली टोल", english: "Hariyali Tole", value: "hariyali_tole" },
    { nepali: "तारा ताल", english: "Tara Tal", value: "tara_tal" },
    { nepali: "गौरी टोल", english: "Gauri Tole", value: "gauti_tole" },
    { nepali: "टिकाराम चोक", english: "Tikaram Chowk", value: "tikaram_chowk" },
    {
        nepali: "गौरी ताराताल टोल",
        english: "Gauri Taratal Tolee",
        value: "gauri_taratal_tole",
    },
    {
        nepali: "सैलेश्वारी टोल",
        english: "Saileshwari Tole",
        value: "saileshwari_tole",
    },
    {
        nepali: "लाली गुराँस टोल",
        english: "Lalikguras Tole",
        value: "lalikguras_tole",
    },
    {
        nepali: "शिव पार्वती टोल",
        english: "Shiv Parwati Tole",
        value: "shiv_parwati_tole",
    },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardTen = [
    { nepali: "जुगेड कटान", english: "Juged Katan", value: "juged_katan" },
    { nepali: "बन्देवी टोल", english: "Bandewi Tole", value: "bandewi_tole" },
    { nepali: "भट्ट टोल", english: "Bhatta Tole", value: "bhatta_tole" },
    {
        nepali: "रम भगत टोल",
        english: "Ram Bhagat Tole",
        value: "ram_bhagat_tole",
    },
    { nepali: "पच्लिपत्ति ", english: "Pachlipatti", value: "pachlipatti" },
    { nepali: "उत्तर टोल", english: "Uttar Tole", value: "uttar_tole" },
    { nepali: "मिलन टोल", english: "Milan Tole", value: "milan_tole" },
    { nepali: "धनगढी गाउँ", english: "Dhangadhi Gaun", value: "dhangadhi_gaun" },
    { nepali: "कान्तिपुर टोल", english: "Kantipur Tole", value: "kantipur_tole" },
    { nepali: "घुइयाघाट", english: "Dhuiya Ghat", value: "dhuiya_ghat" },
    { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
    { nepali: "बि.पी  टोल", english: "B.P Tole", value: "b.p_tole" },
    {
        nepali: "जन कल्याण टोल",
        english: "Jana kalyan Tole",
        value: "jana_kalyan_tole",
    },
    { nepali: "लक्ष्मी टोल", english: "Laxmi Tole", value: "laxmi_tole" },
    { nepali: "पुल्न्हिया", english: "Punlihaya", value: "punlihaya" },
    { nepali: "भगत टोल", english: "Bhagat Tole", value: "bhagat_tole" },
    { nepali: "भगवती टोल", english: "Bhagwati Tole", value: "bhagwati_tole" },
    {
        nepali: "जनजागृति टोल",
        english: "Janajaagriti Tole",
        value: "janajaagriti_tole",
    },
    { nepali: "गणपति टोल", english: "Ganapati Tole", value: "ganapati_tole" },
    { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
    {
        nepali: "लालीगुराँस टोल",
        english: "Laliguras Tole",
        value: "laliguras_tole",
    },
    {
        nepali: "खानेपानी टोल",
        english: "Khanepani Tole",
        value: "khanepani_tole",
    },
    { nepali: "खापरमंडू", english: "khaparmandu", value: "khaparmandu" },
    { nepali: "बि गाउँ", english: "B gaun", value: "b_gaun" },
    { nepali: "अन्य", english: "others", value: "others" },
];

export const toleWardEleven = [
    { nepali: "जोकैया", english: "Jokaiyaa", value: "jokaiyaa" },
    { nepali: "बेला", english: "Belaa", value: "belaa" },
    { nepali: "बर्बब्त्ता", english: "Barbbtaa", value: "barbbtaa" },
    { nepali: "बारबारा", english: "Barbaara", value: "barbaara" },
    { nepali: "धोद्सुवा", english: "Dhodsuwa", value: "dhodsuwa" },
    { nepali: "बस्खेदा", english: "Baskheda", value: "baskheda" },
    { nepali: "बिजुलिया गाउँ", english: "Bijuliya Gaun", value: "bijuliya_gaun" },
    {
        nepali: "श्री कृष्ण टोल",
        english: "Shree Krishna Tole",
        value: "shree_krishna_tole",
    },
    { nepali: "मत्यारी", english: "Matyaari", value: "matyaari" },
    { nepali: "बुजली टोल", english: "Bujali Tole", value: "bujali_tole" },
    { nepali: "बुजुलाया टोल", english: "Bujulaya Tole", value: "bujulaya_tole" },
    { nepali: "मतियारी टोल", english: "Mitiyari Tole", value: "mitiyari_tole" },
    {
        nepali: "मालो मंदरी टोल",
        english: "Lamo Mandri Tole",
        value: "lamo_mandri_tole",
    },
    { nepali: "मलो मदारी", english: "Mela Madari", value: "mela_madari" },
    { nepali: "नमुना टोल", english: "Namuna Tole", value: "namuna_tole" },
    { nepali: "कि सिंह टोल", english: "Ki Singh Tole", value: "ki_singh_tole" },
    { nepali: "बुद्ध टोल", english: "Buddha Tole", value: "buddha_tole" },
    {
        nepali: "दिर्घ ताल टोल",
        english: "Dirgha Tal Tole",
        value: "dirgha_tal_tole",
    },
    { nepali: "तारा नगर", english: "Tara Nagar", value: "tara_nagar" },
    {
        nepali: "दक्षिण काली टोल",
        english: "Dakshin Kali Tole",
        value: "dakshin_kali_tole",
    },
    { nepali: "घुर्सुवा टोल", english: "Ghursuwa Tole", value: "ghursuwa_tole" },
    { nepali: "घोद्सुवा टोल", english: "Dhodsuwa Tole", value: "dhodsuwa_tole" },
    { nepali: "धम्लन टोल", english: "Dhamlan Tole", value: "dhamlan_tole" },
    { nepali: "घुर्सुवा", english: "Ghursuwa", value: "ghursuwa" },
    { nepali: "बैसेगेदा", english: "Baisegeda", value: "baisegeda" },
    { nepali: "आम्बिका टोल", english: "Ambika Tole", value: "ambika_tole" },
    { nepali: "बसिगद्द", english: "Basigadda", value: "basigadda" },
    { nepali: "चौधरी टोल", english: "Chaudhari Tole", value: "chaudhari_tole" },
    { nepali: "फुक्लापुर", english: "Phuklapur", value: "phuklapur" },
    { nepali: "घोत्सुवा टोल", english: "Ghotsuwa Tole", value: "ghotsuwa_tole" },
    { nepali: "बेलादेपुर", english: "Beladepur", value: "beladepur" },
    { nepali: "देव पुर", english: "Dev Pur", value: "dev_pur" },
    { nepali: "देवपुर य", english: "Deb Pur a", value: "deb_pur_a" },
    { nepali: "फुकलपुर", english: "Phuklpur", value: "phuklpur" },
    { nepali: "कैलाली गाउँ", english: "Kailali Gaun", value: "kailali_gaun" },
    { nepali: "लक्ष्मी नगर", english: "Laxmi Nagar", value: "laxmi_nagar" },
    { nepali: "भात्त टोल", english: "Bhatt Tole", value: "bhatt_tole" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardTwelve = [
    { nepali: "डी गाउँ", english: "D Gaun", value: "d_gaun" },
    { nepali: "शि गाउँ", english: "C Gaun", value: "c_gaun" },
    {
        nepali: "सिर्जनसिल टोल",
        english: "Sirjansil Tole",
        value: "sirjansil_tole",
    },
    {
        nepali: "शिवशक्ति टोल",
        english: "Shivshakti Tole",
        value: "shivshakti_tole",
    },
    { nepali: "राइ टोल", english: "Rai Tole", value: "rai_tole" },
    { nepali: "बि गाउँ", english: "B Gaun", value: "b_gaun" },
    { nepali: "पश्चिम टोल", english: "Paschim Tole", value: "paschim_tole" },
    { nepali: "पश्चिम टोल", english: "Paschim Tole.", value: "paschim_tole." },
    { nepali: "सिमल टोल", english: "Simal Tole", value: "simal_tole" },
    { nepali: "सन्तोषी टोल", english: "Santoshi Tole", value: "santoshi_tole" },
    { nepali: "चंकी बजार", english: "Chanki Bajar", value: "chanki_bajar" },
    { nepali: "कम्पनि टोल", english: "Kampani Tole", value: "kampani_tole" },
    {
        nepali: "पश्चिम टोल सिबिर",
        english: "Paschim Tole Siwir",
        value: "paschim_tole_siwir",
    },
    { nepali: "दुर्गा टोल", english: "Dhurga Tole", value: "dhurga_tole" },
    { nepali: "महाकाली टोल", english: "Mahakali Tole", value: "mahakali_tole" },
    {
        nepali: "नव शान्ति टोल",
        english: "Nawa Shanti Tole",
        value: "nawa_shanti_tole",
    },
    {
        nepali: "बुद्ध मन्दिर टोल",
        english: "Buddha Mandir Tole",
        value: "buddha_mandir_tole",
    },
    { nepali: "बुद्ध टोल", english: "Buddha Tole", value: "buddha_tole" },
    { nepali: "ए गाउँ", english: "A Gaun", value: "a_gaun" },
    { nepali: "कृष्ण टोल", english: "Krishna Tole", value: "krishna_tole" },
    { nepali: "गुम्बा दण्ड", english: "Gumba Dand", value: "gumba_dand" },
    { nepali: "जी गाउँ", english: "G Gaun", value: "ji_gaun" },
    { nepali: "पोखरी टोल", english: "Pokhari Tole", value: "pokhari_tole" },
    { nepali: "सृजन टोल", english: "Srijan Tole", value: "srijan_tole" },
    { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
    { nepali: "खुटिया टोल", english: "Khutiya Tole", value: "khutiya_tole" },
    { nepali: "हनुमान टोल", english: "Hanumaan Tole", value: "hanumaan_tole" },
    { nepali: "मन्हेरा सिबिर", english: "Manhara Siwir", value: "mnhera_siwir" },
    { nepali: "मन्हेरा", english: "Manhera", value: "mnhera" },
    { nepali: "बसांत टोल", english: "basanta Tole", value: "basan_tole" },
    {
        nepali: "बसन्त टोल सिबिर",
        english: "basant Tole Sibir",
        value: "basaant_tole_sibir",
    },
    { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
    { nepali: "रामनगर", english: "Ram Nagar", value: "ram_nagar" },
    { nepali: "कुनाल टोल", english: "Kunal Tole", value: "kunal_tole" },
    {
        nepali: "सिद्धार्थ टोल",
        english: "Siddharth Tole",
        value: "siddharth_tole",
    },
    { nepali: "बन्देवी टोल", english: "Bendewi Tole", value: "bendewi_tole" },
    { nepali: "बुद्ध टोल", english: "Buddha Tole 1", value: "buddha_tole_1" },
    { nepali: "मदन टोल", english: "Madan Tole", value: "madan_tole" },
    { nepali: "डाण्डा टोल", english: "Danda Tole", value: "danda_tole" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardThirteen = [
    { nepali: "राजपुर", english: "Rajpur", value: "rajpur" },
    { nepali: "मोहनपुर", english: "Mohanpur", value: "mohanpur" },
    { nepali: "गेटा", english: "Geta", value: "geta" },
    { nepali: "बर्बत्ता", english: "Barbatta", value: "barbatta" },
    { nepali: "मतियारी टोल", english: "Matiyari Tole", value: "matiyari_tole" },
    { nepali: "मलो मतियार", english: "Mlomatiyar", value: "mlomatiyar" },
    { nepali: "अम्बिका टोल", english: "Ambika Tole", value: "ambika_tole" },
    { nepali: "बोरा डा डी", english: "Bora_dadi", value: "bora_dadi" },
    { nepali: "श्रीलंका", english: "Srilanka", value: "srilanka" },
    { nepali: "कैलाली", english: "Kailali", value: "kailali" },
    { nepali: "झलारी गाउँ", english: "Jhalari Gaun", value: "jhalari_gaun" },
    {
        nepali: "उत्तरे बेहंदी",
        english: "Uttare Behandi",
        value: "uttare_behandi",
    },
    { nepali: "शिवनगर", english: "Shivnagar", value: "shivnagar" },
    { nepali: "दुर्गा टोल", english: "Durga Tole", value: "durga_tole" },
    { nepali: "हिमाली टोल", english: "Himalitole", value: "himalitole" },
    {
        nepali: "हिमाली टोल कैलाली गाउँ",
        english: "Himali Tole Kailali Gaun",
        value: "himali_tole_kailali_gaun",
    },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardFourteen = [
    { nepali: "गगुटा टोल", english: "Gaguta Tole", value: "gaguta_tole" },
    { nepali: "घगल टोल", english: "Ghagal Tole", value: "ghagal_tole" },
    { nepali: "बि बिस्त टोल", english: "Bi bit Tole", value: "bi_bit_tole" },
    { nepali: "बिस्ट टोल", english: "Bist Tole", value: "bist_tole" },
    { nepali: "बि गाउँ", english: "B gaun", value: "b_gaun" },
    { nepali: "ओझा टोल", english: "Ojha Tole", value: "ojha_tole" },
    { nepali: "बाजुरा टोल", english: "Bajura Tole", value: "bajura_tole" },
    { nepali: "बझांग टोल", english: "Bhajang Tole", value: "bhajang_tole" },
    { nepali: "सलेन टोल", english: "Slen Tole", value: "slen_tole" },
    { nepali: "सी गाउँ", english: "C Gaun", value: "c_gaun" },
    { nepali: "जी गाउँ", english: "G Gaun", value: "g_gaun" },
    { nepali: "डी गाउँ", english: "D Gaun", value: "d_gaun" },
    { nepali: "शिवालय टोल", english: "Shiwalaya Tole", value: "shiwalaya_tole" },
    { nepali: "इ गाउँ", english: "E Gaun", value: "e_gaun" },
    { nepali: "मालपोत रोड", english: "Malpot Road", value: "malpot_road" },
    { nepali: "एच गाउँ", english: "H Gaun", value: "h_gaun" },
    { nepali: "बुद्ध टोल", english: "Buddha Tole", value: "buddha_tole" },
    { nepali: "कुसुम डांडा", english: "Kusum Danda", value: "kusum_danda" },
    { nepali: "लामांग टोल", english: "Lamang", value: "lamang" },
    { nepali: "बलडांडा", english: "Badanda", value: "badanda" },
    { nepali: "खुटिया टोल", english: "Khutiya Tole", value: "khutiya_tole" },
    { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
    { nepali: "देवरिया", english: "Dewriya", value: "dewriya" },
    { nepali: "हरियाली टोल", english: "Hariyali Tole", value: "hariyali_tole" },
    { nepali: "बक्पुर टोल", english: "Bakpur Tole", value: "bakpur_tole" },
    { nepali: "भातरी टोल", english: "Bhatri Tole", value: "bhatri_tole" },
    { nepali: "बन टोल", english: "Ban Tole", value: "ban_tole" },
    { nepali: "स्याउले", english: "Swaule", value: "swaule" },
    { nepali: "डिपो बजार ", english: "Dipobajar", value: "dipobajar" },
    { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
    { nepali: "२६ नम्बर टोल", english: "26 N._tole", value: "26_n._tole" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardFifteen = [
    { nepali: "कनरी", english: "Kanari", value: "kanari" },
    { nepali: "कोपिला टोल", english: "Kopila tole", value: "kopila_tole" },
    { nepali: "उर्मा", english: "Urma", value: "urma" },
    { nepali: "उमिर", english: "Umir", value: "umir" },
    { nepali: "तर्बरिया", english: "Trbariya", value: "trbariya" },
    {
        nepali: "उत्तेर बेहंदी",
        english: "Uttare behandi",
        value: "uttare_behandi",
    },
    { nepali: "बेहंदी", english: "behandi", value: "behandi" },
    { nepali: "उर्मा कटान", english: "Urma Katan", value: "urma_katan" },
    { nepali: "सुर्मी कटान", english: "Surmi Katan", value: "surmi_katan" },
    { nepali: "चौधरी टोल", english: "Chaudhari Tole", value: "chaudhari_tole" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardSixteen = [
    { nepali: "रामपुर", english: "Rampur", value: "rampur" },
    { nepali: "धशीटा", english: "Dhasita", value: "dhasita" },
    { nepali: "ब्युशाह", english: "Bushhaha", value: "bushhaha" },
    { nepali: "घुराही", english: "Ghurahi", value: "ghurahi" },
    { nepali: "भडा", english: "Bhada", value: "bhada" },
    { nepali: "जल्घुस्रा", english: "Jalghusna", value: "jalghusna" },
    { nepali: "ढाकाटोल", english: "Dhakatole", value: "dhakatole" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardSeventeen = [
    { nepali: "पथरी", english: "Pathari", value: "pathari" },
    { nepali: "धुझाना", english: "Ghujana", value: "ghujana" },
    { nepali: "धुराही", english: "Ghurahi", value: "ghurahi" },
    { nepali: "दमौलिया", english: "Dmauliya", value: "dmauliya" },
    { nepali: "सरौता", english: "Sarauta", value: "sarauta" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardEighteen = [
    { nepali: "बिस्ट टोल", english: "Bist Tole", value: "bist_tole" },
    { nepali: "ए गाउँ", english: "A Gaun", value: "a_gaun" },
    { nepali: "डांडा टोल", english: "Danda Tole", value: "danda_tole" },
    { nepali: "एफ गाउँ", english: "F Gaun", value: "f_gaun" },
    { nepali: "जी गाउँ", english: "G Gaun", value: "g_gaun" },
    { nepali: "अम्खोहिया टोल", english: "Amkohiya Tole", value: "amkohiya_tole" },
    { nepali: "जे गाउँ", english: "J Gaun", value: "j_gaun" },
    { nepali: "तुल्सिबारी", english: "Tulsibari", value: "tulsibari" },
    { nepali: "इ गाउँ", english: "E Gaun", value: "e_gaun" },
    {
        nepali: "लाली गुराँस टोल",
        english: "Laligurans Tole",
        value: "laligurans_tole",
    },
    { nepali: "डिपु", english: "Dipu", value: "dipu" },
    {
        nepali: "स्वर्गद्वारी मार्ग",
        english: "Swargdwari Marg",
        value: "swargdwari_marg",
    },
    { nepali: "डिपो बजार", english: "Dipo Bajar", value: "dipo_bajar" },
    { nepali: "चौधरी टोल", english: "Chaudhari Tole", value: "chaudhari_tole" },
    { nepali: "नुक्लिपुर", english: "Nuklipur", value: "nuklipur" },
    { nepali: "फया मार्ग", english: "Phaya Marg", value: "phaya_marg" },
    { nepali: "गौमती मार्ग", english: "Gaumati Marg", value: "gaumati_marg" },
    { nepali: "गोमती टोल", english: "Gomati Tole", value: "gomati_tole" },
    { nepali: "आदर्श टोल", english: "Aadarsh Tole", value: "aadarsh_tole" },
    { nepali: "खप्तड टोल", english: "Khaptad Tole", value: "khaptad_tole" },
    {
        nepali: "खप्तड टोल, एफ गाउँ",
        english: "Khaptad Tole,_f_gaun",
        value: "khaptad_tole,_f_gaun",
    },
    { nepali: "भाग्वती टोल", english: "Bhagwati Tole", value: "bhagwati_tole" },
    { nepali: "मोहना मार्ग", english: "Mohana Marg", value: "mohana_marg" },
    {
        nepali: "हाइ स्कुल मार्ग",
        english: "Hai School Marg",
        value: "hai_school_marg",
    },
    { nepali: "औधोगिक मार्ग", english: "Audhogik Marg", value: "audhogik_marg" },
    { nepali: "शिव टोल", english: "Shiv Tole", value: "shiv_tole" },
    { nepali: "शिक्षा टोल", english: "Sikshya Tole", value: "sikshya_tole" },
    { nepali: "कालिका टोल", english: "Kalika Tole", value: "kalika_tole" },
    { nepali: "क्याम्पस रोड", english: "Campus Road", value: "campus_road" },
    { nepali: "पशुपति टोल", english: "Pashupati Tole", value: "pashupati_tole" },
    { nepali: "प्रगति टोल", english: "Pragati Tole", value: "pragati_tole" },
    {
        nepali: "मोहनियाल टोल",
        english: "Mohaniyal Tole",
        value: "mohaniyal_tole",
    },
    { nepali: "कृषि मार्ग", english: "Krishi Marg", value: "krishi_marg" },
    { nepali: "आइ गाउँ", english: "I Gaun", value: "i_gaun" },
    { nepali: "कृष्ण नगर", english: "Krishsna Nagar", value: "krishsna_nagar" },
    { nepali: "हिमचुली टोल", english: "Himchuli Tole", value: "himchuli_tole" },
    {
        nepali: "मिम्चुली मार्ग",
        english: "Mimchuli Marg",
        value: "mimchuli_marg",
    },
    { nepali: "जयब्हनु टोल", english: "Jayabhanu Tole", value: "jayabhanu_tole" },
    {
        nepali: "जायभानु मार्ग",
        english: "Jayabhanu Marg",
        value: "jayabhanu_marg",
    },
    { nepali: "इन्द्रदेव टोल", english: "Indradew Tole", value: "indradew_tole" },
    { nepali: "सिता पाथ", english: "Sita Path", value: "sita_path" },
    { nepali: "कान्ति टोल", english: "Kanti Tole", value: "kanti_tole" },
    {
        nepali: "टाउन प्लान बस्ति ",
        english: "Town Plan Basti",
        value: "town_plan_basti",
    },
    { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

export const toleWardNineteen = [
    { nepali: "पोख्रेल टोल", english: "Pokhrel Tole", value: "pokhrel_tole" },
    { nepali: "फुलबारी", english: "Phulbari", value: "phulbari" },
    { nepali: "खेदा", english: "Kheda", value: "kheda" },
    { nepali: "बहुलिया", english: "Bahuliya", value: "bahuliya" },
    { nepali: "भुलिया", english: "Bhuliya", value: "bhuliya" },
    {
        nepali: "जे गाउँडांडा टोल",
        english: "J Gaundada Tole",
        value: "j_gaundada_tole",
    },
    { nepali: "औधोगिक मार्ग", english: "Audhogik Marg", value: "audhogik_marg" },
    { nepali: "आइ गाउँ", english: "I Gaun", value: "i_gaun" },
    {
        nepali: "टाउन प्लान बस्ति",
        english: "Taun Plan Basti",
        value: "taun_plan_basti",
    },
    { nepali: "इ गाउँ", english: "I Gaun 1", value: "i_gaun_1" },
    { nepali: "चाडर टोल", english: "Chadar Tole", value: "chadar_tole" },
    { nepali: "कालिका टोल", english: "Kalika Tole", value: "kalika_tole" },
    { nepali: "फुलबारी ", english: "Phulbari 1", value: "phulbari_1" },
    { nepali: "नया मार्ग", english: "Naya Marg", value: "naya_marg" },
    { nepali: "जे गाउँ", english: "J Gaun", value: "j_gaun" },
    { nepali: "स्कुल मार्ग", english: "Skul Marg", value: "skul_marg" },
    { nepali: "गौक्न्दारी", english: "Gaukndari", value: "gaukndari" },
    { nepali: "जे गाउँ १ ", english: "J Gaun 1", value: "j_gaun_1" },
    { nepali: "जे गाउँ ३", english: "J Gaun 3", value: "j_gaun_3" },
    { nepali: "चौरि", english: "Chauri", value: "chauri" },
    { nepali: "जे गाउँ ४", english: "J Gaun 4", value: "j_gaun_4" },
    { nepali: "ललितपुर", english: "Lalitpur", value: "lalitpur" },
    { nepali: "कृष्ण नगर", english: "Krishna Nagar", value: "krishna_nagar" },
    { nepali: "सुन्दर शक्ति", english: "Sundar Shakti", value: "sundar_shakti" },
    { nepali: "स्कुल मार्ग १", english: "Skul Marg 1", value: "skul_marg_1" },
    {
        nepali: "आइ गाउँ स्कुल मार्ग २",
        english: "I Gaun Skul Marg 2",
        value: "i_gaun_skul_marg_2",
    },
    { nepali: "देवी मन्दिर", english: "Dewi Mandir", value: "dewi_mandir" },
    { nepali: "रप्तौनी", english: "Rptauni", value: "rptauni" },
    { nepali: "गौचरण १", english: "Gaucharan 1", value: "gaucharan_1" },
    { nepali: "गौचरण २", english: "Gaucharan 2", value: "gaucharan_2" },
    { nepali: "निम्पुर मार्ग", english: "Nimpur Marg", value: "nimpur_marg" },
    {
        nepali: "दक्षिण लालिपुर",
        english: "Dakshin Laalipur",
        value: "dakshin_laalipur",
    },
    { nepali: "बोडर टोल", english: "Border Tole", value: "border_tole" },
    { nepali: "बोर्दंदी", english: "Bordadi", value: "bordadi" },
    { nepali: "कुत्ति टोल", english: "Kutti Tole", value: "kutti_tole" },
    { nepali: "मगराति मार्ग", english: "Magarati Marg", value: "magarati_marg" },
    {
        nepali: "बाल सिर्जना १ ",
        english: "Baal Sirjana 1",
        value: "baal_sirjana_1",
    },
    { nepali: "बाल सिर्जना २", english: "Bal Sirjana 2", value: "bal_sirjana_2" },
    { nepali: "पहाडी टोल", english: "Pahadi Tole", value: "pahadi_tole" },
    { nepali: "मोहना मार्ग", english: "Mohana Marg", value: "mohana_marg" },
    { nepali: "खेदा १", english: "Kheda 1", value: "kheda_1" },
    { nepali: "खेदा २", english: "Khelda 2", value: "khelda_2" },
    { nepali: "खेदा ३", english: "Khelda 3", value: "khelda_3" },
    { nepali: "खेदा ४", english: "Khelda 4", value: "khelda_4" },
    { nepali: "खेदा ५", english: "Khelda 5", value: "khelda_5" },
    { nepali: "खेदा ६", english: "Khelda 6", value: "khelda_6" },
    { nepali: "खेदा ७", english: "Khelda 7", value: "khelda_7" },
    { nepali: "शान्ति टोल", english: "Shanti Tole", value: "shanti_tole" },
    { nepali: "भूपु", english: "Bhu Pu", value: "bhu_pu" },
    { nepali: "रानीपोखरी", english: "Ranipokhari", value: "ranipokhari" },
    { nepali: "सान्घारी", english: "Singhari", value: "singhari" },
    { nepali: "२६ नम्बर टोल", english: "26 no. Tole", value: "26_no._tole" },
    { nepali: "ल्ग्गा भागगा", english: "Lgga Bhagga", value: "lgga_bhagga" },
    { nepali: "बाइ पास रोड", english: "Baipass Road", value: "baipass_road" },
    { nepali: "सहकारी रोड", english: "Sahakari Road", value: "sahakari_road" },
    { nepali: "सिविल रोड", english: "Civil Road", value: "civil_road" },
    { nepali: "बि गाउँ", english: "B Gaun", value: "b_gaun" },
    {
        nepali: "१५ मिटर मद्यावती टोल",
        english: "15 Miter Mddwati Tole",
        value: "15_miter_mddwati_tole",
    },
    { nepali: "सिमा वर्ति", english: "Sima Warti", value: "sima_warti" },
    { nepali: "उर्जा अवासाय", english: "Urja Awasaya", value: "urja_awasaya" },
    { nepali: "बेहंदी", english: "Behandi", value: "behandi" },
    {
        nepali: "रामजानकी टोल",
        english: "Ramjanaki Tole",
        value: "ramjanaki_tole",
    },
    { nepali: "उत्तम नगर", english: "Uttam Nagar", value: "uttam_nagar" },
    { nepali: "बस पार्क", english: "Bus Park", value: "bus_park" },
    { nepali: "कोपिला टोल", english: "Kopila Tole", value: "kopila_tole" },
    { nepali: "शान्ति टोल", english: "Shanti Tole 1", value: "shanti_tole_1" },
    { nepali: "अन्य", english: "Others", value: "others" },
];
