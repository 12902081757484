import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { InstituteSurveyList } from "./pages/InstituteSurveyList";
import { InstituteSurveyForm } from "./pages/InstituteSurveyForm";
import { InstituteSurveyEditForm } from "./pages/InstituteSurveyEditForm";
import HomeSurveyForm from "./pages/HomeSurveyForm";
import { DataOverview } from "./pages/DataOverview";
import DataDetail from "./pages/DataDetail";
import AgricultureDataDetail from "./pages/AgricultureDataDetail";
import SearchPage from "./pages/SearchPage";
import LogPage from "./pages/LogPage";
import UserList from "./components/UserData/UserList";
import InstituteSurveyView from "./pages/InstituteSurveyView";
import HomeSurveyDataOverview from "./pages/HomeSurveyDataOverview";
import AgricultureDataOverview from "./pages/AgricultureDataOverview";
import SurveyReport from "./pages/SurveyReport";
import MapPage from "./pages/MapPage";
import HomeSurveyList from "./pages/HomeSurveyList";
import HomeSurveyDetail from "./pages/HomeSurveyDetail";
import Dashboard from "./pages/Dashboard";

const GoogleMaterialPage = lazy(() =>
	import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
	import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
	import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
	// useEffect(() => {
	//   console.log('Base page');
	// }, []) // [] - is required if you need only one call
	// https://reactjs.org/docs/hooks-reference.html#useeffect

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
				{
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from="/" to="/dashboard" />
				}
				<ContentRoute path="/dashboard" component={Dashboard} />
				<ContentRoute
					path="/survey/form/:id/:subCatId/edit/:dataId"
					component={InstituteSurveyEditForm}
				/>
				<ContentRoute
					path="/survey/form/:id/:subCatId/view/:dataId"
					component={InstituteSurveyView}
				/>
				<ContentRoute exact path="/survey" component={InstituteSurveyList} />
				<ContentRoute
					exact
					path="/survey/form/:id"
					component={InstituteSurveyForm}
				/>
				<ContentRoute
					path="/survey/report/:id/:subCatId"
					component={SurveyReport}
				/>
				<ContentRoute exact path="/map" component={MapPage} />
				<ContentRoute
					exact
					path="/home-survey"
					component={HomeSurveyDataOverview}
				/>
				<ContentRoute
					path="/home-survey/report/:parentIndex/:apiKey"
					component={DataDetail}
				/>
				<ContentRoute
					exact
					path="/agriculture-survey"
					component={AgricultureDataOverview}
				/>
				<ContentRoute
					path="/agriculture-survey/report/:parentIndex/:apiKey"
					component={AgricultureDataDetail}
				/>
				<ContentRoute path="/home-survey/list" component={HomeSurveyList} />
				<ContentRoute
					path="/home-survey/detail/:id"
					component={HomeSurveyDetail}
				/>
				<ContentRoute path="/home-survey/form/:id" component={HomeSurveyForm} />
				<ContentRoute path="/home-survey/form" component={HomeSurveyForm} />
				{/* <ContentRoute path="/home-survey/form" component={HomeSurveyForm} /> */}
				{/* <ContentRoute path="/home-survey/form/:id" component={InstituteSurveyForm} /> */}
				<ContentRoute exact path="/organization" component={DataOverview} />
				<ContentRoute path="/organization/detail" component={DataDetail} />
				<ContentRoute path="/search" component={SearchPage} />
				<ContentRoute path="/log" component={LogPage} />
				<ContentRoute path="/users/list" component={UserList} />
				{/* <ContentRoute path="/user/add" component={LogPage} /> */}
				{/* <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} /> */}
				<Redirect to="error/error-v1" />
			</Switch>
		</Suspense>
	);
}
