import axiosHandler from "../../../redux/axiosHandler";

export function uploadImage(data) {
    return axiosHandler.post("/imageUpload/upload", data)
}
export function removeImage(fileName) {
    return axiosHandler.get(`/imageUpload/delete/${fileName}`)
}
export function constructMessage(messageBody) {
    return axiosHandler.post('/sms/message/construct', messageBody)
}